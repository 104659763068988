import { Section } from './ui/Section'
import { SectionContent } from './ui/SectionContent'
import { SectionSubTitle } from './ui/SectionSubTitle'
import { SectionTitle } from './ui/SectionTitle'
import { Variable } from './ui/Variable'

export const RootCanalFillings = ({ values }) => {
  const checkValue = (variable, value) => {
    return variable?.toLowerCase()?.includes(value)
  }

  const checkPostOrCore = () => {
    return checkValue(values?.restorativeType, 'post') || checkValue(values?.restorativeType, 'core')
  }

  return (
    <>
      <Section>
        <p>
          Specify restorative type:{' '}
          <Variable value={values?.restorativeType} isTriggerField={true}>
            filling (or) post and core
          </Variable>{' '}
        </p>

        <br />

        <SectionTitle>Reason for Exam</SectionTitle>
        <SectionSubTitle>Reason for today’s visit:</SectionSubTitle>
        <SectionContent>
          Patient presented for <Variable value={values?.chiefComplaint}>chief complaint</Variable> on tooth{' '}
          <Variable value={values?.toothNumber}>tooth number</Variable>.
        </SectionContent>
        {values?.referredBy && (
          <>
            <SectionContent>
              <SectionSubTitle>Reffered by: </SectionSubTitle>
              <Variable value={values?.referredBy}>Referred by</Variable>
            </SectionContent>
          </>
        )}
        <SectionSubTitle>Reason for root canal:</SectionSubTitle>
        <SectionContent>
          <Variable value={values?.rootCanalReason}>reason for root canal</Variable>
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>Medical History</SectionTitle>
        <SectionContent>Medical history was reviewed with the patient.</SectionContent>
        <SectionContent>
          <ul>
            {values?.bloodPressure && (
              <li className="py-1">
                Blood pressure: <Variable value={values?.bloodPressure}>blood pressure</Variable>
              </li>
            )}
            {values?.heartRate && (
              <li className="py-1">
                Heart rate: <Variable value={values?.heartRate}>heart rate</Variable>
              </li>
            )}
          </ul>
        </SectionContent>
        <SectionSubTitle>Medications:</SectionSubTitle>
        <SectionContent>
          <Variable value={values?.medications}>medications</Variable>
        </SectionContent>
        <SectionSubTitle>Allergies:</SectionSubTitle>
        <SectionContent>
          <Variable value={values?.drugAllergies}>known drug allergies</Variable>
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>Preparation</SectionTitle>
        <SectionSubTitle>Imaging/Radiographs:</SectionSubTitle>
        <SectionContent>
          The following images and radiographs were taken:
          <br />
          <Variable value={values?.imaging}>imaging</Variable>
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>Diagnosis</SectionTitle>
        <SectionContent>
          Following examination, the patient was diagnosed with <Variable value={values?.diagnosis}>diagnosis</Variable>{' '}
          on <Variable value={values?.toothNumber}>tooth number</Variable>.
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>Treatment</SectionTitle>
        <SectionSubTitle>Treatment plan:</SectionSubTitle>
        <SectionContent>
          <ul>
            <li className="py-1">
              Root canal treatment (RCT) on <Variable value={values?.toothNumber}>tooth number</Variable>
            </li>
            <li className="py-1">
              Restoration with a{' '}
              <Variable value={values?.restorativeType} isTriggerField={true}>
                filling (or) post and core
              </Variable>{' '}
              following root canal treatment
            </li>
          </ul>
        </SectionContent>
        {values?.restorativeType && (
          <>
            <SectionSubTitle>Anesthesia:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.anesthesia}>anesthesia</Variable>.
              <br />
              The patient tolerated the anesthesia well.
            </SectionContent>
            <SectionSubTitle>Other medication administered to the patient:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.otherMedications}>other medications</Variable>
            </SectionContent>
            <SectionSubTitle>Root canal procedure:</SectionSubTitle>
            <SectionContent>
              <p>
                The root canal procedure was explained to the patient and all questions were answered before treatment.
                The patient consented to treatment.{' '}
              </p>
              <br />
              <p>
                We proceeded with the rubber dam isolation of{' '}
                <Variable value={values?.toothNumber}>tooth number</Variable>.
              </p>
              <br />
              <p>
                An access cavity was prepared through the{' '}
                <Variable value={values?.toothSurface}>tooth surface</Variable> of{' '}
                <Variable value={values?.toothNumber}>tooth number</Variable>.
              </p>
              <br />
              <p>
                The pulp chamber was debrided to remove infected pulp tissue, and canals were located using an
                endodontic explorer.
              </p>
              <br />
              <p>
                The working length was determined and confirmed with radiographs. The canal was then cleaned and shaped
                to prepare for obturation.
              </p>
              <br />
              <p>
                The canal was irrigated with sodium hypochlorite throughout the procedure to clean the canal and limit
                bacterial exposure.
              </p>
              <br />
              {checkValue(values?.restorativeType, 'filling') && (
                <>
                  <p>
                    In preparation for sealing, the canal was dried with paper points. Then obturated with gutta-percha
                    and sealer.
                  </p>

                  <br />

                  <p>
                    A <Variable value={values?.temporaryOrPermanent}>temporary or permanent</Variable> filling was
                    placed to seal the tooth.
                  </p>
                </>
              )}
              {checkPostOrCore() && (
                <>
                  <p>
                    In preparation for sealing, the canal was dried with paper points. Then obturated with gutta-percha
                    and sealer.
                  </p>

                  <br />

                  <p>
                    Two-thirds of the gutta-percha was subsequently removed from the coronal portion of the canal to
                    create a foundation for the post. The canal was then irrigated and dried.
                  </p>

                  <br />

                  <p>
                    The <Variable value={values?.postType}>post type</Variable> was selected and trial-fitted. Once the
                    fit was confirmed, the post was cemented in place to create a core. The core was built up around the
                    post using composite material to support the crown’s placement.
                  </p>
                </>
              )}
              <br />
              <p>The patient’s restorative chart was completed.</p>

              <br />

              <p>All questions answered and concerns addressed. The patient left in stable condition.</p>
            </SectionContent>
          </>
        )}
      </Section>

      {values?.restorativeType && (
        <>
          <Section>
            <SectionTitle>Next Steps</SectionTitle>

            <br />

            <SectionSubTitle>Postoperative Care:</SectionSubTitle>
            <SectionContent>
              <p>
                {' '}
                The patient was instructed on postoperative care, advising caution when chewing hard foods on the side
                of the treated tooth and continuing oral hygiene such as brushing and flossing daily.{' '}
              </p>

              <br />

              <p>
                {' '}
                We also advised the patient to monitor the area for any sensitivity or discomfort and to contact us if
                any issues arise.
              </p>
            </SectionContent>
            {values?.followup && (
              <>
                <SectionSubTitle>Follow-up:</SectionSubTitle>
                <SectionContent>
                  <Variable value={values?.followup}>follow-up</Variable>
                </SectionContent>
              </>
            )}
          </Section>

          <Section>
            <SectionTitle>Additional Notes:</SectionTitle>
            <SectionContent>
              <Variable value={values?.additionalNotes}>additional notes</Variable>
            </SectionContent>
          </Section>
        </>
      )}
    </>
  )
}

import { z } from 'zod'

const TemplateType = z.enum(['Transcript', 'Note', 'PatientSummary', 'CustomTemplate'])

export const RequiredInfoSchema = z.object({
  name: z.string(),
  variable: z.string(),
  isStarIcon: z.boolean().optional(),
  isOptional: z.boolean().optional()
})

export const TemplateSectionSchema = z.object({
  id: z.string(),
  name: z.string(),
  hideFromClinicalMap: z.boolean().optional(),
  requiredInfo: z.array(RequiredInfoSchema)
})

const LabelSchema = z.object({
  name: z.string(),
  style: z.string()
})

export const TemplateSchema = z.object({
  id: z.string().uuid(),
  isHardCoded: z.boolean(),
  description: z.string().optional(),
  name: z.string(),
  imgName: z.object({}).optional(),
  label: LabelSchema.optional(),
  showClinicalMap: z.boolean(),
  sections: z.array(TemplateSectionSchema)
})

export const TemplateSectionProgressSchema = z.object({
  progress: z.number()
})

export const TemplateResponseSchema = z.object({
  sections: z.record(TemplateSectionSchema.shape.id, z.string().nullable())
})

export const TemplateProgressSchema = z.object({
  sections: z.record(TemplateSectionSchema.shape.id, TemplateSectionProgressSchema)
})

export const EncounterSchema = z.object({
  id: z.string().uuid(),
  templateId: TemplateSchema.shape.id,
  transcript: TemplateResponseSchema.nullable(),
  transcriptTemplate: TemplateSchema,
  templateRaw: z.string().nullable(),
  note: TemplateResponseSchema.nullable(),
  patientSummary: TemplateResponseSchema.nullable(),
  progress: TemplateProgressSchema,
  deletedSections: z.array(TemplateSectionSchema.shape.id),
  isTranscribing: z.boolean(),
  isSpotTranscribing: z.boolean(),
  isGeneratingNote: z.boolean(),
  isGeneratingPatientSummary: z.boolean(),
  wasNoteUpdatedAfterGeneration: z.boolean(),
  rawTranscript: z.string().nullable(),
  createdAt: z.date()
})

export const TranscriptionResponseSchema = z.object({
  transcript: z.string(),
  confidence: z.number(),
  final: z.boolean().optional(),
  startTimestamp: z.number(),
  rId: z.string()
})

export type TemplateType = z.infer<typeof TemplateType>

export type IEncounter = z.infer<typeof EncounterSchema>
export type ITemplate = z.infer<typeof TemplateSchema>
export type ITemplateResponse = z.infer<typeof TemplateResponseSchema>
export type ITemplateSection = z.infer<typeof TemplateSectionSchema>
export type ITranscriptionResponse = z.infer<typeof TranscriptionResponseSchema>

import { useStore } from '@/store'
import { cn, getStage, getStageState } from '@/util'

interface IIndicatorProps {
  stage: number
  label: string
  state: 'Completed' | 'Active' | 'Upcoming'
}

const Indicator: React.FC<IIndicatorProps> = ({ stage, label, state }) => (
  <div className="z-10 flex w-full flex-col items-center justify-center gap-1">
    <div className="relative flex size-8 shrink-0 items-center justify-center rounded-full bg-[#F1F5F9]">
      <div className="absolute inset-0 z-20 flex items-center justify-center">
        <div
          className={cn(
            'size-full scale-0 rounded-full bg-bola transition-all duration-150 ease-out',
            (state === 'Active' || state === 'Completed') && 'scale-100'
          )}
        />
      </div>
      <div className="absolute inset-0 z-30 flex items-center justify-center">
        <div
          className={cn(
            'flex size-full items-center justify-center rounded-full text-center text-base text-primary transition-colors duration-300 ease-out',
            (state === 'Active' || state === 'Completed') && 'text-white'
          )}
        >
          {stage}
        </div>
      </div>
    </div>
    <div className="text-sm text-bola">{label}</div>
  </div>
)

export const Stepper = () => {
  const view = useStore(state => state.view)
  const activeTemplate = useStore(state => state.activeTemplate)

  const stage = getStage(view, !!activeTemplate?.isHardCoded)

  return (
    <div className="hidden h-20 flex-row items-center justify-center p-2 md:flex">
      <div className="relative flex items-end gap-32">
        <div className="absolute left-4 right-4 top-4 h-0.5 overflow-hidden">
          <div className="absolute inset-0 bg-[#F1F5F9]" />
          <div
            className="absolute inset-0 bg-primary transition-transform duration-150 ease-out"
            style={{ transform: `translate3d(${stage < 2 ? '-75%' : stage < 3 ? '-25%' : '0'}, 0, 0)` }}
          />
        </div>
        <Indicator stage={1} label="Setup" state={getStageState(stage, 1)} />
        <Indicator
          stage={2}
          label={activeTemplate?.isHardCoded ? 'Template' : 'Transcript'}
          state={getStageState(stage, 2)}
        />
        <Indicator stage={3} label="Note" state={getStageState(stage, 3)} />
      </div>
    </div>
  )
}

import Dexie, { type EntityTable } from 'dexie'
import { CustomTemplate } from './interfaces/interface'

const db = new Dexie('scribe') as Dexie & {
  template: EntityTable<CustomTemplate, 'id'>
}

db.version(5)
  .stores({
    template: '++id, type, archivedAt, name'
  })
  .upgrade(async tx => {
    await tx.table('template').clear()
  })

export { db }

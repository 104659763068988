import { Check } from 'lucide-react'
import posthog from 'posthog-js'
import { useState } from 'react'

import { Button } from '@/components/ui'
import { useStore } from '@/store'
import { copyToClipboard } from '@/util'

export const EditSidebar = () => {
  const encounter = useStore(state => state.encounter)
  const licenseKey = useStore(state => state.licenseKey)
  const [isCopyingTranscript, setIsCopyingTranscript] = useState(false)

  const onCopyTemplateClick = () => {
    posthog.capture('user_copied_template_raw')
    void copyToClipboard(encounter?.templateRaw || '')
    setIsCopyingTranscript(true)
    setTimeout(() => setIsCopyingTranscript(false), 4000)
  }

  return (
    <Button size="lg" className="mt-2 w-full" onClick={onCopyTemplateClick}>
      {isCopyingTranscript ? (
        <>
          <Check className="mr-1.5 size-4 text-green group-hover/button:text-green" />
          Copied
        </>
      ) : (
        'Copy Note'
      )}
    </Button>
  )
}

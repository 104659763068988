import React, { useEffect, useState } from 'react'
import { useStore } from '@/store'
import { SocketApi, SocketApiListenEvents } from '@/services/SocketApi'
import { Variable } from '../templates/ui/Variable'
import * as Sentry from '@sentry/react'
import './CustomTemplate.css'

const socket = new SocketApi()

const applyInitialPlaceholderHighlight = (template: string) => {
  const parts: (string | JSX.Element)[] = []
  template.split(/(\[\[.*?\]\])/).forEach(part => {
    const match = part.match(/\[\[(.*?)\]\]/)
    if (match) {
      parts.push(<Variable key={match[1]}>{match[1]}</Variable>)
    } else {
      parts.push(part)
    }
  })
  return parts
}

const applyValuesToTemplate = (currentDisplayedNote, newValues) => {
  const updatedParts = currentDisplayedNote.map(part => {
    if (typeof part !== 'string') {
      const variableName = part.props.children.replace(/\[\[|\]\]/g, '')
      let value = newValues[variableName]

      if (value && typeof value === 'object') {
        value = JSON.stringify(value)
      }

      if (value) {
        return (
          <Variable key={variableName} value={value}>
            {value}
          </Variable>
        )
      }
    }
    return part
  })
  return updatedParts
}

const convertToPlainText = (note: (string | JSX.Element)[]) => {
  return note.map(part => (typeof part === 'string' ? part : part.props?.children || '')).join('')
}

export const CustomTemplate: React.FC = () => {
  const { customTemplate, activeCustomTemplate, setFilledNote, setFinalNote, updateEncounter } = useStore(state => ({
    customTemplate: state.customTemplate,
    activeCustomTemplate: state.activeCustomTemplate,
    setFilledNote: state.setFilledValues,
    setFinalNote: state.setFinalNote,
    updateEncounter: state.updateEncounter
  }))

  const [displayedNote, setDisplayedNote] = useState<(string | JSX.Element)[]>([])

  useEffect(() => {
    if (customTemplate?.filledValues) {
      const initialNote = applyInitialPlaceholderHighlight(activeCustomTemplate?.note || '')
      const updatedNote = applyValuesToTemplate(initialNote, customTemplate.filledValues)
      setDisplayedNote(updatedNote)
    } else {
      const initialHighlightedNote = applyInitialPlaceholderHighlight(activeCustomTemplate?.note || '')
      setDisplayedNote(initialHighlightedNote)
      setFilledNote({})
      setFinalNote(convertToPlainText(initialHighlightedNote))
    }
  }, [activeCustomTemplate, setFilledNote, setFinalNote, customTemplate])

  useEffect(() => {
    if (!activeCustomTemplate || !activeCustomTemplate.note) return

    const handleRealTimeTranscript = (data: any) => {
      if (data.final && data.transcript?.length > 5) {
        const rawTranscript = `${useStore.getState().encounter?.rawTranscript || ''} ${data.transcript}`.trim()
        console.log('RAW Transcript: ', rawTranscript)
        updateEncounter({ rawTranscript })

        if (socket.isConnected()) {
          const variableNames = activeCustomTemplate.variables.map(variable => variable.name)

          if (!variableNames || variableNames.length === 0) {
            return
          }
          const stringifiedVariables = JSON.stringify(variableNames, null, 2)

          socket.generateCustomTemplate(stringifiedVariables, rawTranscript)
        } else {
          Sentry.addBreadcrumb({ message: 'Socket not connected, cannot generate template.' })
        }
      }
    }

    const handleCustomTemplateGenerated = (data: any) => {
      try {
        console.log('GENERATED DATA: ', JSON.stringify(data, null, 2))
        const updatedNote = applyValuesToTemplate(displayedNote, data.customTemplateResult)

        setDisplayedNote(updatedNote)

        const filledValues = {
          ...(customTemplate.filledValues || {}),
          ...data.customTemplateResult
        }

        const plainTextNote = convertToPlainText(updatedNote)
        setFinalNote(plainTextNote)

        setFilledNote(filledValues)
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    socket.onUniqueListener(SocketApiListenEvents.REALTIME_TRANSCRIPT, handleRealTimeTranscript)
    socket.onUniqueListener(SocketApiListenEvents.CUSTOM_TEMPLATE_GENERATED, handleCustomTemplateGenerated)
  }, [activeCustomTemplate, displayedNote, setFilledNote, setFinalNote, updateEncounter, socket.getSocket()])

  if (!displayedNote.length) return <div>No custom template selected</div>

  return (
    <div className="mt-5 rounded-lg p-4 shadow-md">
      <div className="custom-template-note">{displayedNote}</div>
    </div>
  )
}

export default CustomTemplate

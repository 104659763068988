import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'
import { useStore } from '@/store'
import posthog from 'posthog-js'
import { toast } from 'sonner'
import {
  Button,
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter
} from '@/components/ui'
import { AlertTriangle } from 'lucide-react'

export const ReportIssue = () => {
  const [isIssueDialogOpen, setIsIssueDialogOpen] = useState(false)
  const [issueDescription, setIssueDescription] = useState('')
  const licenseKey = useStore(state => state.licenseKey)
  const encounter = useStore(state => state.encounter)

  const handleIssueReport = () => {
    const issueId = uuidv4()
    posthog.capture('user_reported_issue', { issueDescription, issueId })
    toast.success('Thank you for reporting the issue. Our team is on it, and we appreciate your patience.')
    Sentry.addBreadcrumb({
      message: `Issue reported:  ${issueDescription}`,
      level: 'error',
      category: 'User Report'
    })
    Sentry.addBreadcrumb({
      message: `Encounter details: ${JSON.stringify(encounter, null, 2)}`,
      level: 'info',
      category: 'Encounter details'
    })
    Sentry.setTag('licensekey', licenseKey)
    Sentry.captureMessage(`Issue reported by: ${licenseKey}, ID: ${issueId}`, 'error')
    setIssueDescription('')
    setIsIssueDialogOpen(false)
  }

  return (
    <Dialog open={isIssueDialogOpen} onOpenChange={setIsIssueDialogOpen}>
      <DialogTrigger asChild>
        <Button size="lg" variant="outline" className="w-full">
          <AlertTriangle className="mr-2" size={18} />
          Report an issue
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Something went wrong?</DialogTitle>
          <DialogDescription className="text-base">
            Please provide some details on the issue you are experiencing
          </DialogDescription>
        </DialogHeader>
        <textarea
          value={issueDescription}
          onChange={e => setIssueDescription(e.target.value)}
          placeholder="E.g. I said this dental term and it was spelled incorrectly OR words keep getting repeated"
          className="mt-2 w-full rounded border p-2"
          rows={8}
        />
        <DialogFooter className="flex flex-col items-center justify-between md:flex-row">
          <span className="mb-2 text-xs text-gray-500 md:mb-0">
            By clicking submit, you agree that no patient identifying information [PII] is present in this transcript
          </span>
          <Button disabled={!issueDescription} onClick={handleIssueReport}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

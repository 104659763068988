import { default as RecordRTC, StereoAudioRecorder } from 'recordrtc'

export enum MicrophoneEvent {
  STARTED = 'started',
  STOPPED = 'stopped',
  DATA = 'data',
  NOT_ALLOWED = 'not-allowed',
  NOT_FOUND = 'not-found',
  DEVICE_CHANGE = 'device-change',
  LEVEL_CHANGE = 'level-change'
}

interface MicrophoneEventListener {
  (evt: CustomEvent): unknown
}

export class Microphone {
  private AUDIO_SLICE_MS = 125
  private AUDIO_BUFFER_SIZE: RecordRTC.Options['bufferSize'] = 16384
  private AUDIO_BITS_PER_SECOND = 128000
  private AUDIO_SAMPLE_RATE = 16000

  private static instance: Microphone

  events: EventTarget = new EventTarget()

  mediaRecorder: MediaRecorder | null = null

  stream: MediaStream | null = null

  tracks: MediaStreamTrack[] = []

  recorder: RecordRTC | null = null

  currentDeviceId: MediaDeviceInfo['deviceId'] | null = null

  constructor(deviceId?: string) {
    if (Microphone.instance) {
      return Microphone.instance
    } else {
      Microphone.instance = this
    }
    void this.initDevice(deviceId)
    // this.initDevice('ecd8c8245484ab1add615f7888ed25a68df1a24b3e808061ab49b39541d27354')
    this.initListeners()
  }

  on(event: string, callback: MicrophoneEventListener): () => void {
    this.events.addEventListener(event, callback as EventListener)
    return () => this.events.removeEventListener(event, callback as EventListener)
  }

  pause(): void {
    this.mediaRecorder?.pause()
  }

  resume(): void {
    this.mediaRecorder?.resume()
  }

  async listDevices(): Promise<MediaDeviceInfo[]> {
    return (await navigator.mediaDevices.enumerateDevices()).filter(device => device.kind === 'audioinput')
  }

  async getDefaultDeviceId(): Promise<MediaDeviceInfo['deviceId'] | null> {
    const devices = await this.listDevices()
    const defaultDevice = devices.find(device => device.deviceId === 'default')
    return defaultDevice ? defaultDevice.deviceId : devices.length > 0 ? devices[0].deviceId : null
  }

  getCurrentDeviceId(): MediaDeviceInfo['deviceId'] | null {
    return this.currentDeviceId
  }

  async setCurrentDeviceId(deviceId: MediaDeviceInfo['deviceId']): Promise<void> {
    this.stopRecording()
    await this.initDevice(deviceId)
  }

  private async initDevice(deviceId?: MediaDeviceInfo['deviceId']): Promise<void> {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          deviceId: deviceId ? { exact: deviceId } : undefined
        }
      })

      this.monitorAudioLevel(this.stream)

      this.recorder = new RecordRTC(this.stream, {
        type: 'audio',
        // @ts-expect-error @todo invalid according to typings, but change needs testing
        mimeType: 'audio/wav;codecs=pcm',
        desiredSampRate: this.AUDIO_SAMPLE_RATE,
        recorderType: StereoAudioRecorder,
        disableLogs: !true,
        numberOfAudioChannels: 1,
        timeSlice: this.AUDIO_SLICE_MS,
        bufferSize: this.AUDIO_BUFFER_SIZE,
        bitsPerSecond: this.AUDIO_BITS_PER_SECOND,
        ondataavailable: (blob: Blob) => {
          this.events.dispatchEvent(new CustomEvent(MicrophoneEvent.DATA, { detail: blob.slice(48) }))
        },
        onstop: () => {
          this.events.dispatchEvent(new CustomEvent(MicrophoneEvent.STOPPED))
        },
        onerror: () => {
          this.events.dispatchEvent(new CustomEvent(MicrophoneEvent.STOPPED))
        }
      })

      this.recorder.startRecording()

      this.currentDeviceId = deviceId || null
    } catch (e: unknown) {
      if (e && typeof e === 'object' && 'name' in e) {
        if (e.name === 'NotAllowedError') {
          this.events.dispatchEvent(new CustomEvent(MicrophoneEvent.NOT_ALLOWED))
          return
        }

        if (e.name === 'NotFoundError') {
          this.events.dispatchEvent(new CustomEvent(MicrophoneEvent.NOT_FOUND))
          return
        }
      }

      throw e
    }
  }

  private initListeners(): void {
    navigator.mediaDevices.addEventListener('devicechange', () => {
      this.events.dispatchEvent(new CustomEvent(MicrophoneEvent.DEVICE_CHANGE))
    })
  }

  private monitorAudioLevel(stream: MediaStream): void {
    const audioContext = new window.AudioContext()
    const analyser = audioContext.createAnalyser()
    const microphone = audioContext.createMediaStreamSource(stream)

    microphone.connect(analyser)
    analyser.fftSize = 256

    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    const updateLevel = () => {
      if (stream !== this.stream) {
        return
      }

      analyser.getByteTimeDomainData(dataArray)

      let sum = 0
      let max = 0

      for (let i = 0; i < bufferLength; i++) {
        const value = (dataArray[i] - 128) / 128
        sum += value * value

        if (Math.abs(value) > max) {
          max = Math.abs(value)
        }
      }

      const rmsLevel = Math.sqrt(sum / bufferLength)
      const scaledLevel = Math.min(rmsLevel * 10, 1)

      this.events.dispatchEvent(new CustomEvent(MicrophoneEvent.LEVEL_CHANGE, { detail: scaledLevel }))

      requestAnimationFrame(updateLevel)
    }

    updateLevel()
  }

  private stopRecording(): void {
    this.recorder?.stopRecording(() => {
      const tracks = this.stream ? this.recorder?.getTracks(this.stream, 'audioinput') : []
      tracks?.forEach(track => track.stop())
    })

    this.stream = null
    this.recorder = null
  }
}

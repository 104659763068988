import posthog from 'posthog-js'
import { toast } from 'sonner'
import { Button } from '@/components/ui'
import { useGenerateNote, useGeneratePdfFromSections } from '@/hooks/api'
import { useStore } from '@/store'
import { copyToClipboard, formatEncounterDate, formatTemplateResponse } from '@/util'
import { ReportIssue } from './ReportIssue'

export const NoteSidebar = () => {
  const encounter = useStore(state => state.encounter)
  const activeTemplate = useStore(state => state.activeTemplate)
  const setView = useStore(state => state.setView)
  const updateEncounter = useStore(state => state.updateEncounter)

  const generateNote = useGenerateNote()
  const generatePdf = useGeneratePdfFromSections()

  if (!encounter) {
    return null
  }

  const onRegenerateNoteClick = async () => {
    updateEncounter({ isTranscribing: false, deletedSections: [] })
    posthog.capture('user_regenerated_note')
    generateNote.mutate(encounter!)
    if (!generateNote.isPending && !encounter?.isGeneratingNote) {
      generateNote.mutate(encounter!)
    }

    setView('Note')
  }

  const onCopyNoteClick = () => {
    posthog.capture('user_copied_note')
    void copyToClipboard(
      encounter.note ? formatTemplateResponse(activeTemplate, encounter.note, encounter.deletedSections) : ''
    )
  }

  const onDownloadNoteClick = () => {
    posthog.capture('user_downloaded_note')
    const promise = generatePdf.mutateAsync({
      fileName: `${formatEncounterDate(encounter.createdAt)} Note`,
      activeTemplate,
      response: encounter.note,
      deletedSections: encounter.deletedSections
    })

    toast.promise(promise, {
      loading: 'Generating PDF...',
      success: 'Downloaded note',
      error: 'Failed to generate PDF. Please try again.'
    })
  }

  return (
    <div className="p-8">
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col items-stretch gap-4">
          <Button size="lg" onClick={onCopyNoteClick}>
            Copy note
          </Button>
          <Button
            size="lg"
            variant="outline"
            onClick={() => void onRegenerateNoteClick()}
            disabled={generateNote.isPending || encounter.isGeneratingNote}
          >
            Regenerate note
          </Button>
          <Button
            size="lg"
            variant="outline"
            onClick={onDownloadNoteClick}
            disabled={generateNote.isPending || encounter.isGeneratingNote || generatePdf.isPending || !encounter.note}
          >
            Download note
          </Button>
        </div>
        <div className="mt-4">
          <ReportIssue />
        </div>
      </div>
    </div>
  )
}

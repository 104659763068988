import React from 'react';
import { useStore } from '@/store';
import NarrativeEditor from './NarrativeEditor';

export const InsuranceNarrative: React.FC = () => {
  const { customTemplate, setEditedInsuranceNarrative, editedInsuranceNarrative } = useStore(state => ({
    customTemplate: state.customTemplate,
    setEditedInsuranceNarrative: state.setEditedInsuranceNarrative,
    editedInsuranceNarrative: state.customTemplate.editedNotes.insuranceNarrative,
  }));

  return (
    <NarrativeEditor
      narrative={customTemplate?.insuranceNarrative}
      editedNarrative={editedInsuranceNarrative}
      setEditedNarrative={setEditedInsuranceNarrative}
      placeholder="Edit your insurance narrative here..."
    />
  );
};

export default InsuranceNarrative;

import { Mic } from 'lucide-react'
import posthog from 'posthog-js'
import { Button } from '@/components/ui'
import { useStore } from '@/store'
import { cn } from '@/util'

interface IMicButtonProps {
  onStart?(): void

  onStop?(): void

  disabled?: boolean
  isOverText?: boolean
}

export const MicButton: React.FC<IMicButtonProps> = ({ onStart, onStop, disabled, isOverText }) => {
  const { view, encounter, licenseKey, updateEncounter } = useStore(state => ({
    view: state.view,
    encounter: state.encounter,
    licenseKey: state.licenseKey,
    updateEncounter: state.updateEncounter
  }))

  const toggleMic = () => {
    window.getSelection()?.removeAllRanges()
    updateEncounter({ isSpotTranscribing: false })

    const isTranscribing = encounter?.isTranscribing
    posthog.capture(isTranscribing ? 'user_stopped_transcribing' : 'user_started_transcribing')
    isTranscribing ? onStop?.() : onStart?.()

    updateEncounter({ isTranscribing: !isTranscribing })
  }

  const isMainMicOn = encounter?.isTranscribing && !encounter?.isSpotTranscribing
  const isSetup = view === 'Setup' || view === 'ProcedureSetup'
  const micColor = isSetup || isMainMicOn ? '#fff' : '#00052f'
  const micBg = isMainMicOn ? 'bg-[#ff0000] hover:bg-[#ff3333]' : 'bg-[#d3d3d3] hover:bg-[#acacac]'
  const textColor = isMainMicOn ? 'text-[#ff0000]' : isSetup ? 'text-primary' : 'text-[#00052f]'

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative m-4 flex items-center justify-center">
        <div
          className={cn(
            'absolute aspect-square h-[140px] w-[140px] rounded-full border-[5px] border-[#acacac] bg-background',
            isMainMicOn && 'animate-pulse-ring border-[20px] border-[#ff8e8e]'
          )}
        />
        <Button
          className={cn(
            'relative z-10 h-28 w-28 flex-shrink-0 rounded-full shadow-none transition-none',
            !isSetup && micBg
          )}
          onClick={toggleMic}
          disabled={disabled}
        >
          <Mic className="h-10 w-10" color={micColor} />
        </Button>
      </div>
      <div
        className={cn(
          'mt-2 rounded-full px-4 py-2 text-center transition-all duration-150 ease-out',
          textColor,
          isSetup && 'relative',
          !isSetup && isOverText && 'bg-background shadow'
        )}
      >
        {isSetup ? 'Click to start' : isMainMicOn ? 'Recording...' : 'Paused'}
      </div>
    </div>
  )
}

import { Section } from './ui/Section'
import { SectionContent } from './ui/SectionContent'
import { SectionSubTitle } from './ui/SectionSubTitle'
import { SectionTitle } from './ui/SectionTitle'
import { Variable } from './ui/Variable'

export const Fillings = ({ values }) => {
  return (
    <>
      <Section>
        <p>
          Specify type of filling {values?.toothShade && <Variable value={values?.toothShade}>tooth shade</Variable>}
          <Variable value={values?.fillingType} isTriggerField={true}>
            {' '}
            composite (or) glass
          </Variable>
        </p>

        <br />

        <SectionTitle>Reason for Exam</SectionTitle>
        <SectionSubTitle>Reason for today’s visit:</SectionSubTitle>
        <SectionContent>
          Patient presented for a {values?.toothShade && <Variable value={values?.toothShade}>tooth shade</Variable>}{' '}
          tooth shade <Variable value={values?.fillingType}> composite (or) glass</Variable> on the{' '}
          <Variable value={values?.toothSurface}>tooth surface</Variable> of tooth{' '}
          <Variable value={values?.toothNumber}>tooth number</Variable>.
        </SectionContent>
        {values?.fillingType && (
          <>
            <SectionSubTitle>Reason for filling:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.fillingReason}>reason for filling</Variable>.
            </SectionContent>
          </>
        )}
      </Section>

      {values?.fillingType && (
        <>
          <Section>
            <SectionTitle>Medical History</SectionTitle>
            <SectionContent>Medical history was reviewed with the patient.</SectionContent>
            <SectionContent>
              <ul>
                {values?.bloodPressure && (
                  <li className="py-1">
                    Blood pressure: <Variable value={values?.bloodPressure}>blood pressure</Variable>
                  </li>
                )}
                {values?.heartRate && (
                  <li className="py-1">
                    Heart rate: <Variable value={values?.heartRate}>heart rate</Variable>
                  </li>
                )}
              </ul>
            </SectionContent>
            <SectionSubTitle>Medications:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.medications}>medications</Variable>
            </SectionContent>
            <SectionSubTitle>Allergies:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.drugAllergies}>known drug allergies</Variable>
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>Preparation and Treatment</SectionTitle>
            <SectionSubTitle>Imaging/Radiographs</SectionSubTitle>
            <SectionContent>
              The following images and radiographs were taken:
              <br />
              <Variable value={values?.imaging}>Imaging</Variable>
            </SectionContent>

            <SectionSubTitle>Anesthesia:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.anesthesia}>Anesthesia</Variable>.
              <br />
              The patient tolerated the anesthesia well.
            </SectionContent>

            {values?.otherMedications && (
              <>
                <SectionSubTitle>Other medication admistrered to the patient:</SectionSubTitle>
                <SectionContent>
                  <Variable value={values?.otherMedications}>Other medications:</Variable>
                </SectionContent>
              </>
            )}

            <SectionSubTitle>Filling preparation:</SectionSubTitle>
            <SectionContent>
              <p>
                I started the procedure by verifying the filling in the chart matches the surface of the tooth in
                question.
              </p>

              <br />

              <p>
                The procedure was explained to the patient, and all questions were answered before treatment. The
                patient consented to treatment.
              </p>

              <br />

              <p>
                In preparation for the filling, the surface of the tooth was cleaned to remove plaque, debris, and
                stains. The tooth was excavated in preparation for the filling and reshaped as needed to match natural
                contours. There was no involvement of the pulp.
              </p>

              <br />

              {values?.fillingType?.toLowerCase()?.includes('composite') && (
                <div>
                  <p>
                    A phosphoric acid etchant was then placed, followed by rinsing and drying of the tooth in
                    preparation for the filling.
                  </p>

                  <br />

                  <p>
                    We scrubbed the tooth with a bonding agent and then incrementally applied the composite resin in the
                    cavity a layer at a time. Each layer was light-cured. After placement, the final restoration was
                    contoured and polished. Occlusions were checked and the patient was satisfied with the occlusions
                    and aesthetics.
                  </p>
                </div>
              )}

              {values?.fillingType?.toLowerCase()?.includes('glass') && (
                <p>
                  The glass ionomer filling was applied in layers and set chemically. The final restoration was lightly
                  contoured and polished. Occlusions were checked and the patient was satisfied with the occlusions.
                </p>
              )}

              <br />

              <p>The patient tolerated the procedure well and was discharged in stable condition.</p>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>Next Steps</SectionTitle> <br />
            <SectionSubTitle>Postoperative Care:</SectionSubTitle>
            <SectionContent>
              <p>
                {' '}
                The patient was instructed on postoperative care, advising caution when chewing hard foods on the side
                of the filling in order to maximize the lifespan of the restoration.
              </p>

              <br />

              <p>
                {' '}
                We also advised the patient to monitor the area for any sensitivity or discomfort and to contact us if
                any issues arise.
              </p>

              <br />
              <Variable value={values?.followup} optional={true}>
                Follow up
              </Variable>
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>Additional Notes:</SectionTitle>
            <SectionContent>
              <Variable value={values?.additionalNotes}>additional notes</Variable>
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>Recommended CDT codes:</SectionTitle>
            <SectionContent>
              The following CDT codes have been reviewed carefully and relate to the treatment provided in this
              encounter:
              <Variable value={values?.cdtCodes}>CDT Codes</Variable>
            </SectionContent>
          </Section>
        </>
      )}
    </>
  )
}

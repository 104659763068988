import { Section } from './ui/Section'
import { SectionContent } from './ui/SectionContent'
import { SectionSubTitle } from './ui/SectionSubTitle'
import { SectionTitle } from './ui/SectionTitle'
import { Variable } from './ui/Variable'

export const CrownPermanent = ({ values }) => {
  const checkValue = (variable, value) => {
    return values?.[variable]?.toLowerCase()?.includes(value)
  }

  return (
    <>
      <Section>
        <p>
          Specify crown fabrication method{' '}
          <Variable value={values?.fabricationMethod} isTriggerField={true}>
            onsite or lab
          </Variable>
        </p>

        <br />

        <SectionTitle>Reason for Exam</SectionTitle>
        <SectionSubTitle>Reason for today's visit:</SectionSubTitle>
        <SectionContent>
          Patient presented for a {values?.toothShade && <Variable value={values?.toothShade}>tooth shade</Variable>}{' '}
          permanent <Variable value={values?.crownType}>crown type</Variable> crown on tooth{' '}
          <Variable value={values?.toothNumber}>tooth number</Variable>.
        </SectionContent>
        <SectionSubTitle>Reason for crown:</SectionSubTitle>
        <SectionContent>
          <Variable value={values?.crownReason}>reason for crown</Variable>
        </SectionContent>
        <SectionContent>
          The crown was fabricated <Variable value={values?.fabricationMethod}>fabrication method</Variable>.
        </SectionContent>
        {values?.referredBy && (
          <>
            <SectionContent>
              <SectionSubTitle>Referred by: </SectionSubTitle>
              <Variable value={values?.referredBy}>Referred by</Variable>
            </SectionContent>
          </>
        )}
      </Section>

      {values?.fabricationMethod && (
        <>
          <Section>
            <SectionTitle>Medical History</SectionTitle>
            <SectionContent>Medical history was reviewed with the patient.</SectionContent>
            <SectionSubTitle>Medications:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.medications}>medications</Variable>
            </SectionContent>
            <SectionSubTitle>Allergies:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.drugAllergies}>known drug allergies</Variable>
            </SectionContent>
            {values?.bloodPressure && (
              <SectionContent>
                Blood pressure: <Variable value={values?.bloodPressure}>blood pressure</Variable>
              </SectionContent>
            )}
            {values?.heartRate && (
              <SectionContent>
                Heart rate: <Variable value={values?.heartRate}>heart rate</Variable>
              </SectionContent>
            )}
          </Section>

          <Section>
            <SectionTitle>Preparation</SectionTitle>
            <SectionContent>
              The crown procedure was explained to the patient and all questions were answered before treatment. The
              patient consented to treatment.
            </SectionContent>
            <SectionSubTitle>Imaging/Radiographs:</SectionSubTitle>
            <SectionContent>
              The following images and radiographs were taken:
              <br />
              <Variable value={values?.imaging}>imaging</Variable>
            </SectionContent>
            <SectionContent>
              <Variable value={values?.toothNumber}>tooth number</Variable> was prepped for a permanent{' '}
              <Variable value={values?.crownType}>crown type</Variable> crown.
            </SectionContent>
            <SectionSubTitle>Anesthesia:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.anesthesia}>anesthesia</Variable>.
              <br />
              The patient tolerated the anesthesia well.
            </SectionContent>
            {values?.otherMedications && (
              <SectionContent>
                <Variable value={values?.otherMedications}>other medications</Variable>
              </SectionContent>
            )}
            {values?.hemostaticSolution && (
              <SectionContent>
                <Variable value={values?.hemostaticSolution}>hemostatic solution</Variable>
              </SectionContent>
            )}
            {checkValue('fabricationMethod', 'site') && (
              <SectionContent>
                <p>
                  {' '}
                  After an intraoral scan, a permanent <Variable value={values?.crownType}>crown type</Variable> crown
                  was fabricated onsite for the patient.
                </p>
                <br />
                <p>
                  {' '}
                  The crown was tried in the patient's mouth. All margins were confirmed and contacts were confirmed
                  with floss.
                </p>

                <br />

                <p> The crown was cleaned, then stain and glaze were applied and sintered.</p>

                <br />

                <p> The crown was tried again in the patient's mouth and margins were confirmed with an explorer.</p>

                <br />

                <p>
                  {' '}
                  No open margins were present. Contacts were checked with floss while holding the crown down before
                  cementation.
                </p>

                <br />

                <p>
                  {' '}
                  The crown was then cleaned with cleaning solution, and the tooth was thoroughly cleaned and dried.
                </p>
              </SectionContent>
            )}
            {checkValue('fabricationMethod', 'lab') && (
              <SectionContent>
                The temporary crown placed during the previous visit was carefully loosened and removed from{' '}
                <Variable value={values?.toothNumber}>tooth number</Variable>
                Any remaining temporary cement was removed and the surface of the tooth was cleaned, rinsed, and dried
                in preparation for the new crown. The new, permanent crown was tried in the patient’s mouth. All margins
                were confirmed and contacts were confirmed with floss. No open margins were present. Contacts were
                checked with floss while holding the crown down before cementation.
              </SectionContent>
            )}
          </Section>

          <Section>
            <SectionTitle>Crown delivery</SectionTitle>
            <SectionSubTitle>Bonding:</SectionSubTitle>
            <SectionContent>
              Bonding agent was applied to the crown and air dried.
              {values?.acidEtching && (
                <SectionContent>
                  <Variable value={values?.acidEtching}>acid etching</Variable>
                </SectionContent>
              )}
              {values?.desensitizer && (
                <SectionContent>
                  <Variable value={values?.desensitizer}>desensitizer</Variable>
                </SectionContent>
              )}
              Bonding agent was then applied to the tooth, air thinned, and cured.
            </SectionContent>
            <SectionSubTitle>Cementation:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.cementationProcess}>cementation process</Variable>
            </SectionContent>
            <SectionContent>
              All excess cement was removed with scalers and floss. Contacts were confirmed again with floss. Occlusion
              was checked and proper adjustments were made. The patient was satisfied with both the occlusion and
              aesthetics. The patient's restorative chart was completed. All questions answered and concerns addressed.
              The patient left in stable condition.
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>Next steps</SectionTitle>
            <SectionSubTitle>Postoperative Care:</SectionSubTitle>
            <SectionContent>
              <p>
                {' '}
                The patient was instructed on postoperative care, including continuing daily brushing and flossing and
                avoiding hard sticky foods on the side of the crown in order to maximize the lifespan of the
                restoration.
              </p>

              <br />

              <p>
                {' '}
                We also advised the patient to monitor the area for any sensitivity or discomfort and to contact us if
                any issues arise.
              </p>
            </SectionContent>
            {values?.followup && (
              <SectionContent>
                <Variable value={values?.followup}>follow up</Variable>
              </SectionContent>
            )}
          </Section>

          <Section>
            <SectionTitle>Additional notes:</SectionTitle>
            <SectionContent>
              <Variable value={values?.additionalNotes}>additional notes</Variable>
            </SectionContent>
          </Section>
        </>
      )}
    </>
  )
}

import posthog from 'posthog-js'
import { useEffect, useState } from 'react'
import { useStore } from '@/store'
import { SocketApi } from '@/services/SocketApi'
import back from '/assets/images/back-button.svg'
import { db } from '@/db'
import { CustomTemplate } from '@/interfaces/interface'
import * as Sentry from '@sentry/react'

const Socket = new SocketApi()

export const ProcedureSelect = () => {
  const { setActiveCustomTemplate, setView, startNewEncounter } = useStore()
  const [selectedTemplateId, setSelectedTemplateId] = useState('')
  const [dbCustomTemplates, setDbCustomTemplates] = useState<CustomTemplate[]>([])

  useEffect(() => {
    db.template
      ?.toArray?.()
      .then((templates: CustomTemplate[]) => {
        setDbCustomTemplates(templates)
      })
      .catch(error => {
        Sentry.captureException(error)
      })
  }, [])

  const handleTemplateChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const templateId = parseInt(event.target.value, 10)
    setSelectedTemplateId(event.target.value)

    const selectedCustomTemplate = dbCustomTemplates.find(template => template.id === templateId)
    if (selectedCustomTemplate) {
      setActiveCustomTemplate(selectedCustomTemplate)
      return
    }
  }

  const handleBackButton = () => {
    setView('Setup')
    startNewEncounter()
    Socket.finishEncounter()
  }

  return (
    <div className="mt-5">
      <button
        onClick={handleBackButton}
        className="ml-30 fixed left-tenth bg-transparent text-lg text-[#010D83] hover:underline"
      >
        <span className="flex items-center">
          <img src={back} alt="Back icon" className="mr-2" />
          Back
        </span>
      </button>

      <div className="mt-4 flex flex-col items-center gap-2">
        <div className="text-lg font-semibold text-blue-800">Choose a Custom Template</div>
      </div>

      <div className="mt-6 flex justify-center">
        <select
          value={selectedTemplateId}
          onChange={handleTemplateChange}
          className="block w-1/2 rounded-lg border border-gray-300 p-3 text-lg focus:border-blue-600 focus:outline-none"
        >
          {dbCustomTemplates.length > 0 && (
            <option value="" disabled>
              Custom Templates
            </option>
          )}
          {dbCustomTemplates.length == 0 && (
            <option value="" disabled>
              No custom templates yet
            </option>
          )}
          {dbCustomTemplates
            .filter(template => !template.archivedAt && template?.id != null)
            .map(template => (
              <option key={String(template.id)} value={String(template.id)}>
                {template.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

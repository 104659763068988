import { useNavigate } from 'react-router-dom'
import { Button } from '@/components/ui'
import bolaLogo from '/assets/bola-logo.svg'
import toothIcon from '/assets/images/good.svg'

export const WelcomePage = () => {
  const navigate = useNavigate()

  return (
    <section className="m-44 min-h-screen">
      <header className="h-50 flex">
        <img src={bolaLogo} className="h-20" alt="Bola Logo" />
      </header>
      <main className="mt-28">
        <article>
          <div className="flex">
            <img src={toothIcon} />
            <h2 className="mr-4 content-center text-4xl font-bold">Congrats, you are all set!</h2>
          </div>
        </article>
        <article>
          <p className="mt-5 text-2xl">You are ready to use your AI Scribe on this device! </p>
        </article>

        <Button
          className="grow-1 ml-5 mt-8 h-12 w-32 rounded-md bg-primary  text-white"
          onClick={() => {
            navigate('/')
          }}
        >
          Get Started
        </Button>
      </main>
    </section>
  )
}

import React from 'react';
import { useStore } from '@/store';
import NarrativeEditor from './NarrativeEditor';

export const PatientNarrative: React.FC = () => {
  const { customTemplate, setEditedPatientNarrative, editedPatientNarrative } = useStore(state => ({
    customTemplate: state.customTemplate,
    setEditedPatientNarrative: state.setEditedPatientNarrative,
    editedPatientNarrative: state.customTemplate.editedNotes.patientNarrative,
  }));

  return (
    <NarrativeEditor
      narrative={customTemplate?.patientNarrative}
      editedNarrative={editedPatientNarrative}
      setEditedNarrative={setEditedPatientNarrative}
      placeholder="Edit your patient narrative here..."
    />
  );
};

export default PatientNarrative;

import React, { useState } from 'react'
import { ChevronDown } from 'lucide-react'
import { Button, Progress, Label } from '@/components/ui'
import { cn } from '@/util'
import { useStore } from '@/store'
import { DotFilledIcon, CheckIcon, StarIcon } from '@radix-ui/react-icons'

type CustomTemplateVariable = {
  name: string
  displayName: string
  isStarIcon?: boolean
}

type GeneratedTemplate = {
  variables: CustomTemplateVariable[]
}

export const CustomTemplateClinicalMap: React.FC = () => {
  const [isShowingDetails, setIsShowingDetails] = useState(true)
  const { view, activeCustomTemplate, customTemplate } = useStore(state => ({
    view: state.view,
    activeCustomTemplate: state.activeCustomTemplate,
    customTemplate: state.customTemplate
  }))

  let variables: CustomTemplateVariable[] = []
  const filledValues = customTemplate?.filledValues ?? {}

  if (view === 'CustomTemplate' && activeCustomTemplate) {
    variables = activeCustomTemplate.variables ?? []
  } else if (
    customTemplate.generatedTemplate &&
    typeof customTemplate.generatedTemplate === 'object' &&
    'variables' in customTemplate.generatedTemplate
  ) {
    variables = (customTemplate.generatedTemplate as GeneratedTemplate).variables ?? []
  }

  const isVariableFilled = (variableName: string) => filledValues[variableName] && filledValues[variableName].length > 0

  const totalVariables = variables.length
  const completedVariables = variables.filter(variable => isVariableFilled(variable.name)).length
  const overallProgress = totalVariables > 0 ? Math.round((completedVariables / totalVariables) * 100) : 0

  const renderVariableList = () => {
    if (variables.length === 0) {
      return (
        <div className="flex flex-col justify-center">
          <p className="mt-4 text-[15px] text-[#6b7280]">
            Please paste your custom template and click "Create Custom Template" to display the variables.
          </p>
        </div>
      )
    }

    return (
      <div className="max-h-[60vh] overflow-y-auto px-4 pb-4">
        <ul className="space-y-2">
          {variables.map((variable, index) => (
            <li key={index} className="flex items-center gap-2 text-[15px] text-[#1a1c23]">
              {isVariableFilled(variable.name) ? (
                <CheckIcon className="size-5 text-[#5AE4C3]" />
              ) : variable.isStarIcon ? (
                <StarIcon className="size-5 text-[#E0C300]" />
              ) : (
                <DotFilledIcon className="size-5 text-[#050a17]" />
              )}
              <span>{variable.displayName}</span>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div id="customTemplateClinicalMap">
      <div className="flex flex-row items-center justify-between">
        <Label className="-mb-1 text-lg text-black">Clinical map</Label>
        <Button
          variant="ghost"
          className="-mr-4 gap-1 text-[#0f172a] md:flex"
          onClick={() => setIsShowingDetails(prev => !prev)}
        >
          {isShowingDetails ? 'Hide details' : 'Show details'}
          <ChevronDown
            className={cn('size-4 transition-all duration-150 ease-out', isShowingDetails && 'rotate-180')}
          />
        </Button>
      </div>
      {isShowingDetails && renderVariableList()}
      <div className="mt-6 flex flex-row items-center gap-2">
        <Progress className="h-2 w-full bg-border" value={overallProgress} />
        <div className="text-[15px] font-bold text-[#00052f]">{overallProgress}%</div>
      </div>
    </div>
  )
}

import { Section } from './ui/Section'
import { SectionContent } from './ui/SectionContent'
import { SectionSubTitle } from './ui/SectionSubTitle'
import { SectionTitle } from './ui/SectionTitle'
import { Variable } from './ui/Variable'

export const CrownTemporary = ({ values }) => {
  const checkValue = (variable, value) => {
    return values?.[variable]?.toLowerCase()?.includes(value)
  }

  return (
    <>
      <Section>
        <p>
          Specify crown material{' '}
          <Variable value={values?.crownMaterial} isTriggerField={true}>
            bis-acryl, acrylic resin, or metal
          </Variable>
        </p>
      </Section>

      <Section>
        <SectionTitle>Reason for Exam</SectionTitle>
        <SectionSubTitle>Reason for today's visit:</SectionSubTitle>
        <SectionContent>
          Patient presented for a temporary <Variable value={values?.crownMaterial}>crown material</Variable> crown on
          tooth <Variable value={values?.toothNumber}>tooth number</Variable>.
        </SectionContent>
        <SectionSubTitle>Reason for crown:</SectionSubTitle>
        <SectionContent>
          <Variable value={values?.crownReason}>reason for crown</Variable>
        </SectionContent>
        {values?.referredBy && (
          <>
            <SectionContent>
              <SectionSubTitle>Referred by: </SectionSubTitle>
              <Variable value={values?.referredBy}>Referred by</Variable>
            </SectionContent>
          </>
        )}
      </Section>

      {values?.crownMaterial && (
        <>
          <Section>
            <SectionTitle>Medical History</SectionTitle>
            <SectionContent>Medical history was reviewed with the patient.</SectionContent>
            <SectionSubTitle>Medications:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.medications}>medications</Variable>
            </SectionContent>
            <SectionSubTitle>Allergies:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.drugAllergies}>known drug allergies</Variable>
            </SectionContent>
            {values?.bloodPressure && (
              <SectionContent>
                Blood pressure: <Variable value={values?.bloodPressure}>blood pressure</Variable>
              </SectionContent>
            )}
            {values?.heartRate && (
              <SectionContent>
                Heart rate: <Variable value={values?.heartRate}>heart rate</Variable>
              </SectionContent>
            )}
          </Section>

          <Section>
            <SectionTitle>Preparation</SectionTitle>
            <SectionContent>
              The crown procedure was explained to the patient and all questions were answered before treatment. The
              patient consented to treatment.
            </SectionContent>
            <SectionSubTitle>Imaging/Radiographs:</SectionSubTitle>
            <SectionContent>
              The following images and radiographs were taken:
              <br />
              <Variable value={values?.imaging}>imaging</Variable>
            </SectionContent>
            <SectionContent>
              <Variable value={values?.toothNumber}>tooth number</Variable> was prepped for a temporary{' '}
              <Variable value={values?.crownMaterial}>crown material</Variable> crown.
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>Treatment</SectionTitle>
            <SectionSubTitle>Anesthesia:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.anesthesia}>anesthesia</Variable>.
              <br />
              The patient tolerated the anesthesia well.
            </SectionContent>
            {values?.otherMedications && (
              <SectionContent>
                <Variable value={values?.otherMedications}>other medications</Variable>
              </SectionContent>
            )}
            {values?.hemostaticSolution && (
              <SectionContent>
                <Variable value={values?.hemostaticSolution}>hemostatic solution</Variable>
              </SectionContent>
            )}
            {checkValue('crownMaterial', 'bis') && (
              <SectionContent>
                An impression of <Variable value={values?.toothNumber}>tooth number</Variable> was taken in order to
                create a temporary crown. The filling impression was filled with bis-acryl composite material and seated
                in the patient's mouth to ensure proper contact and alignment while the mixture set. The set impression
                was removed from the patient's mouth, trimmed, and polished to ensure a comfortable and smooth fit. The
                temporary crown was tried again in the patient's mouth. No open margins were present. Contacts were
                checked with floss while holding the crown down before cementation.
              </SectionContent>
            )}
            {checkValue('crownMaterial', 'resin') && (
              <SectionContent>
                An impression of <Variable value={values?.toothNumber}>tooth number</Variable> was taken in order to
                create a temporary crown. The acrylic was manually mixed and the impression mold was lined with a
                separator to prevent the acrylic from sticking to the mold. The mixed acrylic was packed into the
                pre-operative impression before being seated in the patient's mouth to ensure proper contact and
                alignment.The impression was held in place for a few minutes while it was set. The set impression was
                removed from the patient's mouth, trimmed, and polished to ensure a comfortable and smooth fit.
              </SectionContent>
            )}
            {checkValue('crownMaterial', 'metal') && (
              <SectionContent>
                A pre-formed metal crown was selected for <Variable value={values?.toothNumber}>tooth number</Variable>{' '}
                as a temporary crown. The initial fit of the temporary metal crown was checked in the patient's mouth to
                understand adjustments to be made in size and shape. Adjustments were made to the temporary crown by way
                of trimming excess material and contouring the shape of the metal to fit the tooth. The fit was checked
                throughout this process. Finally, the edges of the crown were crimped as needed to prevent displacement
                and reduce the likelihood of leakage.
              </SectionContent>
            )}
          </Section>

          <Section>
            <SectionTitle>Crown delivery</SectionTitle>
            {values?.desensitizer && (
              <SectionContent>
                <Variable value={values?.desensitizer}>desensitizer</Variable>
              </SectionContent>
            )}
            <SectionSubTitle>Cementation:</SectionSubTitle>
            <SectionContent>
              <Variable value={values?.cementationProcess}>cementation process</Variable>
            </SectionContent>
            <SectionContent>
              All excess cement was removed with scalers and floss. Contacts were confirmed again with floss. Occlusion
              was checked and proper adjustments were made. The patient was satisfied with both the occlusion and
              aesthetics. The patient's restorative chart was completed. All questions answered and concerns addressed.
              The patient left in stable condition.
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>Next steps</SectionTitle>
            <SectionSubTitle>Postoperative Care:</SectionSubTitle>
            <SectionContent>
              The patient was instructed on the following post-operative instructions:
              <div>
                <ol className="ml-10 list-decimal">
                  <li>
                    Avoid eating or drinking on the side of the temporary crown for 30 minutes until the cement has
                    fully set
                  </li>
                  <li>Continue daily routine brushing and flossing</li>
                  <li>
                    Use a soft-bristled toothbrush and a non-abrasive toothpaste to clean around the temporary crown
                  </li>
                  <li>
                    When flossing around the temporary crown, slide the floss out from the side instead of lifting it up
                    to avoid dislodging the crown
                  </li>
                  <li>
                    It is normal to experience sensitivity to hot, cold, or pressure but this sensitivity should subside
                    in a few days. If not, contact our office for assistance
                  </li>
                  <li>
                    In the event that the temporary crown loosens or falls off, try to reseat it and contact our office
                    immediately for additional instructions
                  </li>
                  <li>
                    Your appointment for a permanent crown has been scheduled. Please keep this appointment, as it is
                    important to replace the temporary crown as soon as possible with a permanent one to ensure the
                    health and stability of your tooth
                  </li>
                </ol>
              </div>
            </SectionContent>
            {values?.followup && (
              <SectionContent>
                <Variable value={values?.followup}>follow up</Variable>
              </SectionContent>
            )}
          </Section>

          <Section>
            <SectionTitle>Additional notes:</SectionTitle>
            <SectionContent>
              <Variable value={values?.additionalNotes}>additional notes</Variable>
            </SectionContent>
          </Section>
        </>
      )}
    </>
  )
}

import { Check, Copy, Mic, Redo, Trash2 } from 'lucide-react'
import posthog from 'posthog-js'
import React, { useEffect } from 'react'

import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui'
import { useStaticTemplates } from '@/hooks/api'
import { ITranscriptionResponse, TemplateType } from '@/models'
import { SocketApi, SocketApiListenEvents } from '@/services/SocketApi'
import { useStore } from '@/store'
import { cn, copyToClipboard, formatResponse } from '@/util'

interface ITextEditorProps {
  type: TemplateType
}

const socket = new SocketApi()

export const TextEditor: React.FC<ITextEditorProps> = ({ type }) => {
  const initialState = React.useRef(useStore.getState())
  const encounter = useStore(state => state.encounter)
  const toggleSection = useStore(state => state.toggleSection)
  const isTranscribing = useStore(state => state.encounter?.isTranscribing)
  const isSpotTranscribing = useStore(state => state.encounter?.isSpotTranscribing)
  const updateEncounter = useStore(state => state.updateEncounter)
  const templates = useStaticTemplates()
  const [copiedIndex, setCopiedIndex] = React.useState<number | null>(null)

  const textareaRefs = React.useRef<HTMLTextAreaElement[]>([])
  const micSpotBtn = React.useRef<HTMLButtonElement | null>(null)

  const getValue = (section): string => {
    const initialEncounter = initialState.current.encounter
    if (type === 'Transcript') {
      return initialEncounter?.transcript?.sections[section.id] ?? ''
    }
    if (type === 'Note') {
      const sectionContent = initialEncounter?.note?.sections[section.id] ?? ''
      return formatResponse(sectionContent)
    }
    const sectionContent = initialEncounter?.patientSummary?.sections[section.id] ?? ''
    return formatResponse(sectionContent)
  }

  const setValue = (section, value: string) => {
    let _encounter = {}
    if (type === 'Transcript') {
      _encounter = {
        ...encounter,
        wasNoteUpdatedAfterGeneration: encounter?.note ? true : false,
        transcript: {
          ...encounter?.transcript,
          sections: {
            ...encounter?.transcript?.sections,
            [section.id]: value
          }
        }
      }
    }
    if (type === 'Note') {
      _encounter = {
        ...encounter,
        note: {
          ...encounter?.note,
          sections: {
            ...encounter?.note?.sections,
            [section.id]: value
          }
        }
      }
    }
    if (type === 'PatientSummary') {
      _encounter = {
        ...encounter,
        patientSummary: {
          ...encounter?.patientSummary,
          sections: {
            ...encounter?.patientSummary?.sections,
            [section.id]: value
          }
        }
      }
    }
    useStore.getState().updateEncounter(_encounter)
  }

  const selectedRect = () => {
    try {
      const selection = window.getSelection()
      const selectedText = selection?.toString()
      const range = selection?.getRangeAt(0)
      const rect = range?.getBoundingClientRect()

      return { selectedText, rect, selection, range }
    } catch (_) {
      return {
        selectedText: null,
        rect: null,
        range: null,
        selection: null
      }
    }
  }

  useEffect(() => {
    if (type === 'Transcript') {
      socket.onUniqueListener(SocketApiListenEvents.REALTIME_TRANSCRIPT, (data: ITranscriptionResponse) => {
        const isSpotTranscribing = useStore.getState().encounter?.isSpotTranscribing
        if (data?.final) {
          console.log(`Transcription: ${data.transcript} ID: ${data.rId}`)
          if (isSpotTranscribing) {
            const { selectedText, range, selection } = selectedRect()
            if (selectedText && range && selection) {
              const scrollPosition = window.scrollY
              const selectedRange = selection.getRangeAt(0)
              const { startContainer, startOffset, endContainer, endOffset } = selectedRange

              if (startContainer?.textContent && endContainer?.textContent) {
                range.deleteContents()
                const transcriptText = ` ${data.transcript} `
                range.insertNode(document.createTextNode(transcriptText))
                range.collapse(false)

                window.scrollTo(0, scrollPosition)
                selection.removeAllRanges()

                const newStartOffset = Math.min(startOffset, startContainer.textContent.length)
                const newEndOffset = Math.min(
                  startOffset + transcriptText.length,
                  endContainer.textContent.length + transcriptText.length
                )

                selectedRange.setStart(startContainer, newStartOffset)
                selectedRange.setEnd(endContainer, newEndOffset)
                selection.addRange(selectedRange)

                const sectionId = (range.commonAncestorContainer as any).id
                const value = range.commonAncestorContainer.textContent || ''
                useStore.getState().updateEncounter({
                  transcript: {
                    sections: {
                      ...useStore.getState().encounter?.transcript?.sections,
                      [sectionId]: value
                    }
                  }
                })
              }
            }
          } else {
            useStore.getState().addTranscriptionResponse(data)
            initialState.current = useStore.getState()
          }
        }
      })
    }
    updateEncounter({ isSpotTranscribing: false })
  }, [type, socket.getSocket()])

  const { template, response } =
    type === 'Transcript'
      ? { template: encounter?.transcriptTemplate, response: encounter?.transcript }
      : type === 'Note'
        ? {
            template: templates.data?.Note?.find(template => template.id === encounter?.templateId),
            response: encounter?.note
          }
        : { template: templates.data?.PatientSummary, response: encounter?.patientSummary }

  if (!encounter || !template) {
    return null
  }

  const handleSelect = () => {
    updateEncounter({ isSpotTranscribing: false })
    const { selectedText, rect } = selectedRect()
    if (selectedText && rect && micSpotBtn.current) {
      const middleX = rect.x + (rect.width - 40) / 2
      const y = rect.y - 50
      micSpotBtn.current.style.top = `${y}px`
      micSpotBtn.current.style.left = `${middleX}px`
      updateEncounter({ isSpotTranscribing: true })
    }
  }

  const toggleSpotMic = () => {
    if (isTranscribing) {
      const { selectedText } = selectedRect()
      updateEncounter({ isTranscribing: false, isSpotTranscribing: !!selectedText })
    } else {
      updateEncounter({ isTranscribing: true })
    }
  }

  return (
    <div className="mb-12 flex flex-col">
      <Button
        ref={el => {
          micSpotBtn.current = el
        }}
        className={cn(
          'fixed z-10 size-10 rounded-full border-2 border-white p-0 shadow transition-none',
          encounter?.isTranscribing ? 'bg-[#ff0000] hover:bg-[#ff3333]' : 'bg-[#5A71E4]',
          isSpotTranscribing ? 'flex' : 'hidden'
        )}
        onClick={toggleSpotMic}
      >
        <Mic className="size-5" color={encounter?.isTranscribing ? '#fff' : '#fff'} />
      </Button>
      {template.sections.map((section, index) => {
        const isDeleted = encounter.deletedSections.includes(section.id)
        const copyStyles =
          'h-5 w-5 text-muted-foreground transition-colors duration-100 group-hover/button:text-foreground'

        return (
          <div key={section.id} className="group relative flex max-w-[800px] flex-col items-stretch">
            <label
              htmlFor={section.id}
              className={cn(
                'absolute left-8 right-8 top-4 flex h-7 cursor-text flex-row items-center justify-between gap-2 md:justify-normal',
                isDeleted && 'cursor-default'
              )}
            >
              <div
                className={cn(
                  'relative text-lg text-primary transition-opacity duration-150 ease-out',
                  isDeleted && 'opacity-50'
                )}
              >
                <div
                  className={cn(
                    'absolute left-0 right-0 top-1/2 h-[2px] origin-left scale-x-0 bg-primary transition-transform duration-150 ease-out',
                    isDeleted && 'scale-x-100'
                  )}
                />
                {section.name}
              </div>
              <div className="flex flex-row items-center transition-opacity duration-100 group-hover:pointer-events-auto group-hover:opacity-100 md:pointer-events-none md:opacity-0">
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        className={cn(
                          'group/button w-9 rounded-full transition-all duration-150 ease-out',
                          isDeleted && 'w-0'
                        )}
                        onClick={() => {
                          posthog.capture('user_copied_section')
                          void copyToClipboard(response?.sections[section.id] ?? '')
                          setCopiedIndex(index)
                          setTimeout(() => setCopiedIndex(null), 4000)
                        }}
                      >
                        {index === copiedIndex ? (
                          <Check className={cn(copyStyles, 'text-green group-hover/button:text-green')} />
                        ) : (
                          <Copy className={copyStyles} />
                        )}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Copy</p>
                    </TooltipContent>
                  </Tooltip>
                  {type !== 'Transcript' && (
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="group/button rounded-full"
                          onClick={() => {
                            isDeleted
                              ? posthog.capture('user_restored_section')
                              : posthog.capture('user_deleted_section')
                            toggleSection(section.id)
                          }}
                        >
                          {isDeleted ? (
                            <Redo className="h-5 w-5 text-muted-foreground transition-colors duration-100 group-hover/button:text-foreground" />
                          ) : (
                            <Trash2 className="h-5 w-5 text-muted-foreground transition-colors duration-100 group-hover/button:text-red-600" />
                          )}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{isDeleted ? 'Restore' : 'Delete'}</p>
                      </TooltipContent>
                    </Tooltip>
                  )}
                </TooltipProvider>
              </div>
            </label>
            <div
              ref={el => {
                textareaRefs[index] = el
              }}
              id={section.id}
              className={cn(
                'w-full resize-none appearance-none px-8 pb-4 pt-12 text-lg outline-none focus:outline-none disabled:overflow-hidden disabled:bg-white group-last:pb-56',
                type !== 'Transcript' && 'transition-all duration-150 ease-out',
                isDeleted && 'invisible h-0 cursor-default pb-6 pt-8 text-white'
              )}
              contentEditable={!isTranscribing}
              suppressContentEditableWarning={true}
              onInput={(e: any) => setValue(section, e.currentTarget.textContent)}
              onSelect={handleSelect}
              dangerouslySetInnerHTML={{ __html: getValue(section) }}
            />
          </div>
        )
      })}
    </div>
  )
}

import React from 'react'
import { useStore } from '@/store'
import NarrativeEditor from './NarrativeEditor'

export const CustomTemplateEdit: React.FC = () => {
  const { customTemplate, setEditedTemplateNote, editedFinalNote } = useStore(state => ({
    customTemplate: state.customTemplate,
    setEditedTemplateNote: state.setEditedTemplateNote,
    editedFinalNote: state.customTemplate?.editedNotes?.templateNote || ''
  }))

  return (
    <NarrativeEditor
      narrative={customTemplate?.finalNote}
      editedNarrative={editedFinalNote}
      setEditedNarrative={setEditedTemplateNote}
      placeholder="Edit your clinical note here..."
    />
  )
}

export default CustomTemplateEdit

import React from 'react'

import { useStore } from '@/store'
import { cn } from '@/util'

export const Edit = () => {
  const initialState = React.useRef(useStore.getState())

  const html = initialState?.current?.encounter?.templateRaw?.replace(/\n/g, '<br>')

  const parseChilds = (childs: HTMLElement[]) => {
    let text = ''
    for (let i = 0; i < childs.length; i++) {
      if (childs[i].tagName === 'BR') {
        text += '\n'
      } else if (childs[i].tagName === 'DIV') {
        const childText = parseChilds(childs[i].childNodes as unknown as HTMLElement[])
        if (childText.trim()) {
          text += '\n' + childText + '\n'
        } else {
          text += '\n'
        }
      } else {
        text += childs[i].textContent
      }
    }
    return text
  }

  const setValue = (childs: HTMLElement[]) => {
    useStore.getState().updateEncounter({ templateRaw: parseChilds(childs) })
  }

  return (
    <div className="mb-12 flex flex-col">
      <div
        id="template-raw"
        className="w-full resize-none appearance-none px-8 pb-4 text-lg outline-none focus:outline-none"
        suppressContentEditableWarning={true}
        contentEditable={true}
        onInput={(e: any) => setValue(e.currentTarget.childNodes)}
        dangerouslySetInnerHTML={{ __html: html as string }}
      />
    </div>
  )
}

export const tabForViews = [
  'Note',
  'Summary',
  'CustomTemplateEdit',
  'CustomTemplatePatientNarrative',
  'InsuranceNarrative'
]

export const NARRATIVE_TAB_CONFIGS = {
  CustomTemplatePatientNarrative: {
    title: 'Patient Summary',
    contentKey: 'patientNarrative',
    fileName: 'Patient_Summary',
    showClinicalMap: false
  },
  InsuranceNarrative: {
    title: 'Insurance Narrative',
    contentKey: 'insuranceNarrative',
    fileName: 'Insurance_Narrative',
    showClinicalMap: false
  },
  CustomTemplateEdit: {
    title: 'Clinical Note',
    contentKey: 'templateNote',
    fileName: 'Clinical_Note',
    showClinicalMap: false
  },
  CustomTemplate: {
    title: 'Clinical Map',
    showClinicalMap: true
  }
}

export const CUSTOM_TEMPLATE_VIEWS = ['CustomTemplateEdit', 'CustomTemplatePatientNarrative', 'InsuranceNarrative']

export const STAGE_3_VIEWS = ['Edit', 'CustomTemplateEdit', 'CustomTemplatePatientNarrative', 'InsuranceNarrative']

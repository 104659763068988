import { App } from '@/App'
import { Authentication } from '@/components/Authentication'
import { TermsAndConditions } from '@/components/TermsAndConditions'
import { WelcomePage } from '@/components/WelcomePage'
import { Toaster } from '@/components/ui'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from './components/ProtectedRoute'

import Intercom from '@intercom/messenger-js-sdk'
import './index.css'

if (import.meta.env.VITE_POSTHOG_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY!, {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    capture_pageview: true,
    capture_pageleave: true,
    autocapture: false,
    mask_all_text: true
  })
}

Intercom({
  app_id: import.meta.env.VITE_INTERCOM_ID
})

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  debug: true,
  environment: import.meta.env.MODE,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0
})

const Main = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="/" element={<App />} />
          </Route>
          <Route path="/authenticate" element={<Authentication />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/welcome" element={<WelcomePage />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </React.StrictMode>
  )
}

const container = document.getElementById('root')!
const root = createRoot(container)
root.render(<Main />)

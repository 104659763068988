import { z } from 'zod'

import {
  ITemplate,
  TemplateProgressSchema,
  TemplateResponseSchema,
  TemplateSchema,
  TemplateSectionProgressSchema,
  TemplateSectionSchema
} from '@/models'

import examNoteImage from '/assets/images/exam-note.svg'
import soapNoteImage from '/assets/images/soap-note.svg'
import procedureNoteImage from '/assets/images/procedure-note.svg'
import quickNotImage from '/assets/images/quick-note.svg'

const createTemplateSchema = <T extends string>(sectionIds: [T, ...T[]]) => ({
  template: TemplateSchema.omit({ sections: true }).extend({
    sections: z.array(TemplateSectionSchema.omit({ id: true }).extend({ id: z.enum(sectionIds) }))
  }),
  response: TemplateResponseSchema.omit({ sections: true }).extend({
    sections: z.record(z.enum(sectionIds), z.string().nullable())
  }),
  progress: TemplateProgressSchema.omit({ sections: true }).extend({
    sections: z.record(z.enum(sectionIds), TemplateSectionProgressSchema)
  })
})

export const {
  template: PatientIntakeTemplateSchema,
  response: PatientIntakeResponseSchema,
  progress: PatientIntakeProgressSchema
} = createTemplateSchema([
  'REASON_FOR_EXAM',
  'MEDICAL_HISTORY',
  'EXAMINATION',
  'DIAGNOSIS',
  'NEXT_STEPS',
  'PREPARATION',
  'ADDITIONAL_NOTES',
  'CDT_CODES',
  'TREATMENT',
  'CROWN_DELIVERY',
  'SUBJECTIVE',
  'OBJECTIVE',
  'ASSESSMENT',
  'PLAN',
  'QUICK_NOTES',
  'INCOMPLETE_NOTES'
])

export const { template: PatientSummaryTemplateSchema, response: PatientSummaryResponseSchema } = createTemplateSchema([
  'PATIENT_SUMMARY',
  'GUIDANCE',
  'NEXT_STEPS'
])

export type IPatientIntakeTemplate = z.infer<typeof PatientIntakeTemplateSchema>
export type IPatientIntakeResponse = z.infer<typeof PatientIntakeResponseSchema>
export type IPatientIntakeProgress = z.infer<typeof PatientIntakeProgressSchema>

export type IPatientSummaryTemplate = z.infer<typeof PatientSummaryTemplateSchema>
export type IPatientSummaryResponse = z.infer<typeof PatientSummaryResponseSchema>

const patientSummaryTemplate: IPatientSummaryTemplate = {
  id: '2a4a21f4-c6f2-472a-961a-8a404db01ee8',
  name: 'Patient Summary',
  isHardCoded: false,
  showClinicalMap: true,
  sections: [
    {
      id: 'PATIENT_SUMMARY',
      name: 'Patient Summary',
      requiredInfo: []
    },
    {
      id: 'GUIDANCE',
      name: 'Guidance',
      requiredInfo: []
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: []
    }
  ]
} as const

const examNote: IPatientIntakeTemplate = {
  id: 'PATIENT_INTAKE',
  name: 'Exam Note',
  description: "Document comprehensive exams using Bola's multi-purpose note format",
  showClinicalMap: true,
  isHardCoded: false,
  imgName: examNoteImage,
  label: {
    name: 'UPDATED',
    style: 'border-[#5C73E4] bg-[#5C73E4]  group-hover:text-[#324FDD] group-hover:bg-[#F3F4F6]'
  },
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Chief complaint', variable: 'chiefComplaint' },
        { name: 'Referred by', variable: 'referredBy' }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Last dental exam', variable: 'lastDentalExam' },
        { name: 'Current and new medications', variable: 'medications' },
        { name: 'Drug and contact allergies', variable: 'allergies' },
        { name: 'Patient habits', variable: 'patientHabits' },
        { name: 'Past surgeries', variable: 'pastSurgeries' },
        { name: 'Existing conditions', variable: 'existingConditions' }
      ]
    },
    {
      id: 'EXAMINATION',
      name: 'Examination',
      requiredInfo: [
        { name: 'Vitals (blood pressure, pulse, etc.)', variable: 'vitals' },
        { name: 'Imaging (x-rays, radiographs, etc.)', variable: 'imaging' },
        { name: 'Extraoral exam', variable: 'extraoralExam' },
        { name: 'Intraoral exam', variable: 'intraoralExam' }
      ]
    },
    {
      id: 'DIAGNOSIS',
      name: 'Diagnosis and Treatment',
      requiredInfo: [
        { name: 'Diagnosis (condition or cosmetic concern)', variable: 'diagnosis' },
        { name: 'Watch areas', variable: 'watchAreas' }
      ]
    },
    {
      id: 'TREATMENT',
      name: 'Treatment',
      requiredInfo: [
        { name: 'Treatment plan', variable: 'treatmentPlan' },
        { name: 'Consent to treatment', variable: 'consentToTreatment' },
        { name: 'Procedures performed', variable: 'proceduresPerformed' },
        { name: 'Anesthesia (type, method, and amounts)', variable: 'anesthesia' },
        { name: 'Materials and amounts used', variable: 'materialsAndAmount' },
        { name: 'Other medications administered', variable: 'otherMedications' }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [
        { name: 'Recommendations', variable: 'recommendations' },
        { name: 'Referrals', variable: 'referrals' },
        { name: 'Follow-up appointment(s)', variable: 'followUps' }
      ]
    },
    {
      id: 'CDT_CODES',
      name: 'CDT codes',
      hideFromClinicalMap: true,
      requiredInfo: [{ name: 'CDT Codes', variable: 'cdtCodes' }]
    },
    {
      id: 'ADDITIONAL_NOTES',
      name: 'Additional Notes',
      requiredInfo: [{ name: 'Additional notes', variable: 'additionalNotes' }]
    }
  ]
} as const

const fillingsProcedure: IPatientIntakeTemplate = {
  id: 'FILLINGS',
  name: 'Fillings',
  isHardCoded: true,
  showClinicalMap: true,
  label: {
    name: 'UPDATED',
    style: 'ml-2 text-tiny border-[#5C73E4] bg-[#5C73E4]  group-hover:text-[#324FDD] group-hover:bg-[#F3F4F6]'
  },
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Tooth shade (optional)', variable: 'toothShade', isOptional: true },
        { name: 'Type of filling', variable: 'fillingType', isStarIcon: true },
        { name: 'Tooth surface', variable: 'toothSurface' },
        { name: 'Tooth number', variable: 'toothNumber' },
        { name: 'Reason for filling', variable: 'fillingReason' }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Blood pressure (optional)', variable: 'bloodPressure', isOptional: true },
        { name: 'Heart rate (optional)', variable: 'heartRate', isOptional: true },
        { name: 'Medications', variable: 'medications' },
        { name: 'Allergies', variable: 'drugAllergies' }
      ]
    },
    {
      id: 'PREPARATION',
      name: 'Preparation and Treatment',
      requiredInfo: [
        { name: 'Imaging and radiographs', variable: 'imaging' },
        { name: 'Anesthesia (type, method, and amounts)', variable: 'anesthesia' },
        { name: 'Other medications administered (optional)', variable: 'otherMedications', isOptional: true }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [{ name: 'Follow-up (optional)', variable: 'followup', isOptional: true }]
    },
    {
      id: 'ADDITIONAL_NOTES',
      name: 'Additional notes',
      requiredInfo: [{ name: 'Additional notes', variable: 'additionalNotes' }]
    }
  ]
} as const

const rootCanalFillingProcedure: IPatientIntakeTemplate = {
  id: 'ROOT_CANAL_FILLINGS',
  name: 'Root Canal',
  isHardCoded: true,
  showClinicalMap: true,
  label: {
    name: 'UPDATED',
    style: 'ml-2 text-tiny border-[#5C73E4] bg-[#5C73E4]  group-hover:text-[#324FDD] group-hover:bg-[#F3F4F6]'
  },
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Chief complaint', variable: 'chiefComplaint' },
        { name: 'Tooth number', variable: 'toothNumber' },
        { name: 'Referred by (optional)', variable: 'referredBy', isOptional: true },
        { name: 'Reason for root canal', variable: 'rootCanalReason' }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Blood pressure (optional)', variable: 'bloodPressure', isOptional: true },
        { name: 'Heart rate (optional)', variable: 'heartRate', isOptional: true },
        { name: 'Medications', variable: 'medications' },
        { name: 'Allergies', variable: 'drugAllergies' }
      ]
    },
    {
      id: 'PREPARATION',
      name: 'Preparation',
      requiredInfo: [
        { name: 'Imaging and radiographs', variable: 'imaging' },
        { name: 'Diagnosis', variable: 'diagnosis' }
      ]
    },
    {
      id: 'TREATMENT',
      name: 'Treatment',
      requiredInfo: [
        { name: 'Restorative type', variable: 'restorativeType', isStarIcon: true },
        { name: 'Anesthesia (type, method, and amounts)', variable: 'anesthesia' },
        { name: 'Other medications administered (optional)', variable: 'otherMedications', isOptional: true },
        { name: 'Tooth surface', variable: 'toothSurface' },
        { name: 'Temporary or permanent filling (if applicable)', variable: 'temporaryOrPermanent' },
        { name: 'Post type (if applicable)', variable: 'postType' }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [{ name: 'Follow-up (optional)', variable: 'followup', isOptional: true }]
    },
    {
      id: 'ADDITIONAL_NOTES',
      name: 'Additional notes',
      requiredInfo: [{ name: 'Additional notes', variable: 'additionalNotes' }]
    }
  ]
} as const

const crownPermanentProcedure: IPatientIntakeTemplate = {
  id: 'CROWN_PERMANENT',
  name: 'Permanent crown',
  isHardCoded: true,
  showClinicalMap: true,
  label: {
    name: 'BETA',
    style: 'ml-2 text-tiny border-[#66B2FF] bg-[#66B2FF]  group-hover:text-[#324FDD] group-hover:bg-[#F3F4F6]'
  },
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Tooth shade (optional)', variable: 'toothShade', isOptional: true },
        { name: 'Crown type', variable: 'crownType' },
        { name: 'Tooth number', variable: 'toothNumber' },
        { name: 'Reason for crown', variable: 'crownReason' },
        { name: 'Onsite or lab-fabrication', variable: 'fabricationMethod', isStarIcon: true },
        { name: 'Referred by (optional)', variable: 'referredBy', isOptional: true }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Blood pressure (optional)', variable: 'bloodPressure', isOptional: true },
        { name: 'Heart rate (optional)', variable: 'heartRate', isOptional: true },
        { name: 'Medications', variable: 'medications' },
        { name: 'Allergies', variable: 'drugAllergies' }
      ]
    },
    {
      id: 'PREPARATION',
      name: 'Preparation',
      requiredInfo: [
        { name: 'Pre-op and/or post-op Imaging', variable: 'imaging' },
        { name: 'Anesthesia (type, method, and amounts)', variable: 'anesthesia' },
        { name: 'Other medications administered (optional)', variable: 'otherMedications', isOptional: true },
        { name: 'Hemostatic solution (optional)', variable: 'hemostaticSolution', isOptional: true }
      ]
    },
    {
      id: 'CROWN_DELIVERY',
      name: 'Crown Delivery',
      requiredInfo: [
        { name: 'Acid etching (optional)', variable: 'acidEtching', isOptional: true },
        { name: 'Desensitizer (optional)', variable: 'desensitizer', isOptional: true },
        { name: 'Cementation process', variable: 'cementationProcess' }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [{ name: 'Follow-up (optional)', variable: 'followUp', isOptional: true }]
    },
    {
      id: 'ADDITIONAL_NOTES',
      name: 'Additional Notes',
      requiredInfo: [{ name: 'Additional notes', variable: 'additionalNotes' }]
    }
  ]
} as const

const procedureNote: IPatientIntakeTemplate = {
  // for displaying card title on setup only - not a template
  id: 'PROCEDURE_NOTE',
  name: 'Templated Notes',
  description: 'Record procedures using our pre-built "fill in the blanks" style templates',
  imgName: procedureNoteImage,
  label: {
    name: 'UPDATED',
    style: 'border-[#5C73E4] bg-[#5C73E4]  group-hover:text-[#324FDD] group-hover:bg-[#F3F4F6]'
  },
  isHardCoded: false,
  showClinicalMap: true,
  sections: []
} as const

const otherProcedure: IPatientIntakeTemplate = {
  id: 'OTHER_NOTE',
  name: 'Other Note',
  isHardCoded: false,
  showClinicalMap: true,
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Chief complaint', variable: 'chiefComplaint' },
        { name: 'Last dental exam', variable: 'lastDentalExam' },
        { name: 'Referred by', variable: 'referredBy' }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Medications', variable: 'medications' },
        { name: 'Allergies', variable: 'allergies' },
        { name: 'Patient habits', variable: 'patientHabits' },
        { name: 'Past surgeries', variable: 'pastSurgeries' },
        { name: 'Existing conditions', variable: 'existingConditions' },
        { name: 'Vitals', variable: 'vitals' }
      ]
    },
    {
      id: 'EXAMINATION',
      name: 'Examination',
      requiredInfo: [
        { name: 'Perio charting', variable: 'perioCharting' },
        { name: 'Restorative charting', variable: 'restorativeCharting' },
        { name: 'Whitening or veneers', variable: 'whiteningOrVeneers' },
        { name: 'X-rays and imaging', variable: 'xraysAndImaging' },
        { name: 'Prophy', variable: 'prophy' },
        { name: 'Materials and amounts used', variable: 'materialsAndAmount' }
      ]
    },
    {
      id: 'DIAGNOSIS',
      name: 'Diagnosis and Treatment',
      requiredInfo: [
        { name: 'Areas to watch', variable: 'areasToWatch' },
        { name: 'Conditions such as caries, gingivitis, bruxism, oral pathology, etc.', variable: 'conditions' },
        { name: 'Missing teeth and replacement options', variable: 'missingTeeth' },
        {
          name: 'Cosmetic concerns such as stained teeth, discolored, or misshapen teeth',
          variable: 'cosmeticConcerns'
        }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [
        { name: 'Recommendations', variable: 'recommendations' },
        { name: 'Referrals', variable: 'referrals' },
        { name: 'Next visit', variable: 'nextVisit' },
        { name: 'Treatment plan', variable: 'treatmentPlan' }
      ]
    }
  ]
} as const

const extractionProcedure: IPatientIntakeTemplate = {
  id: 'EXTRACTION_NOTE',
  name: 'Extraction Note',
  isHardCoded: false,
  showClinicalMap: true,
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Chief complaint', variable: 'chiefComplaint' },
        { name: 'Last dental exam', variable: 'lastDentalExam' },
        { name: 'Referred by', variable: 'referredBy' }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Medications', variable: 'medications' },
        { name: 'Allergies', variable: 'allergies' },
        { name: 'Patient habits', variable: 'patientHabits' },
        { name: 'Past surgeries', variable: 'pastSurgeries' },
        { name: 'Existing conditions', variable: 'existingConditions' },
        { name: 'Vitals', variable: 'vitals' }
      ]
    },
    {
      id: 'EXAMINATION',
      name: 'Examination',
      requiredInfo: [
        { name: 'Perio charting', variable: 'perioCharting' },
        { name: 'Restorative charting', variable: 'restorativeCharting' },
        { name: 'Whitening or veneers', variable: 'whiteningOrVeneers' },
        { name: 'X-rays and imaging', variable: 'xraysAndImaging' },
        { name: 'Prophy', variable: 'prophy' },
        { name: 'Materials and amounts used', variable: 'materialsAndAmount' }
      ]
    },
    {
      id: 'DIAGNOSIS',
      name: 'Diagnosis and Treatment',
      requiredInfo: [
        { name: 'Areas to watch', variable: 'areasToWatch' },
        { name: 'Conditions such as caries, gingivitis, bruxism, oral pathology, etc.', variable: 'conditions' },
        { name: 'Missing teeth and replacement options', variable: 'missingTeeth' },
        {
          name: 'Cosmetic concerns such as stained teeth, discolored, or misshapen teeth',
          variable: 'cosmeticConcerns'
        }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [
        { name: 'Recommendations', variable: 'recommendations' },
        { name: 'Referrals', variable: 'referrals' },
        { name: 'Next visit', variable: 'nextVisit' },
        { name: 'Treatment plan', variable: 'treatmentPlan' }
      ]
    }
  ]
} as const

const implantProcedure: IPatientIntakeTemplate = {
  id: 'IMPLANT_NOTE',
  name: 'Implant Note',
  isHardCoded: false,
  showClinicalMap: true,
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Chief complaint', variable: 'chiefComplaint' },
        { name: 'Last dental exam', variable: 'lastDentalExam' },
        { name: 'Referred by', variable: 'referredBy' }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Medications', variable: 'medications' },
        { name: 'Allergies', variable: 'allergies' },
        { name: 'Patient habits', variable: 'patientHabits' },
        { name: 'Past surgeries', variable: 'pastSurgeries' },
        { name: 'Existing conditions', variable: 'existingConditions' },
        { name: 'Vitals', variable: 'vitals' }
      ]
    },
    {
      id: 'EXAMINATION',
      name: 'Examination',
      requiredInfo: [
        { name: 'Perio charting', variable: 'perioCharting' },
        { name: 'Restorative charting', variable: 'restorativeCharting' },
        { name: 'Whitening or veneers', variable: 'whiteningOrVeneers' },
        { name: 'X-rays and imaging', variable: 'xraysAndImaging' },
        { name: 'Prophy', variable: 'prophy' },
        { name: 'Materials and amounts used', variable: 'materialsAndAmount' }
      ]
    },
    {
      id: 'DIAGNOSIS',
      name: 'Diagnosis and Treatment',
      requiredInfo: [
        { name: 'Areas to watch', variable: 'areasToWatch' },
        { name: 'Conditions such as caries, gingivitis, bruxism, oral pathology, etc.', variable: 'conditions' },
        { name: 'Missing teeth and replacement options', variable: 'missingTeeth' },
        {
          name: 'Cosmetic concerns such as stained teeth, discolored, or misshapen teeth',
          variable: 'cosmeticConcerns'
        }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [
        { name: 'Recommendations', variable: 'recommendations' },
        { name: 'Referrals', variable: 'referrals' },
        { name: 'Next visit', variable: 'nextVisit' },
        { name: 'Treatment plan', variable: 'treatmentPlan' }
      ]
    }
  ]
} as const

const prophyProcedure: IPatientIntakeTemplate = {
  id: 'PROPHY_NOTE',
  name: 'Prophy Note',
  isHardCoded: false,
  showClinicalMap: true,
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Chief complaint', variable: 'chiefComplaint' },
        { name: 'Last dental exam', variable: 'lastDentalExam' },
        { name: 'Referred by', variable: 'referredBy' }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Medications', variable: 'medications' },
        { name: 'Allergies', variable: 'allergies' },
        { name: 'Patient habits', variable: 'patientHabits' },
        { name: 'Past surgeries', variable: 'pastSurgeries' },
        { name: 'Existing conditions', variable: 'existingConditions' },
        { name: 'Vitals', variable: 'vitals' }
      ]
    },
    {
      id: 'EXAMINATION',
      name: 'Examination',
      requiredInfo: [
        { name: 'Perio charting', variable: 'perioCharting' },
        { name: 'Restorative charting', variable: 'restorativeCharting' },
        { name: 'Whitening or veneers', variable: 'whiteningOrVeneers' },
        { name: 'X-rays and imaging', variable: 'xraysAndImaging' },
        { name: 'Prophy', variable: 'prophy' },
        { name: 'Materials and amounts used', variable: 'materialsAndAmount' }
      ]
    },
    {
      id: 'DIAGNOSIS',
      name: 'Diagnosis and Treatment',
      requiredInfo: [
        { name: 'Areas to watch', variable: 'areasToWatch' },
        { name: 'Conditions such as caries, gingivitis, bruxism, oral pathology, etc.', variable: 'conditions' },
        { name: 'Missing teeth and replacement options', variable: 'missingTeeth' },
        {
          name: 'Cosmetic concerns such as stained teeth, discolored, or misshapen teeth',
          variable: 'cosmeticConcerns'
        }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [
        { name: 'Recommendations', variable: 'recommendations' },
        { name: 'Referrals', variable: 'referrals' },
        { name: 'Next visit', variable: 'nextVisit' },
        { name: 'Treatment plan', variable: 'treatmentPlan' }
      ]
    }
  ]
} as const

const crownTemporaryProcedure: IPatientIntakeTemplate = {
  id: 'CROWN_TEMPORARY',
  name: 'Temporary Crown',
  isHardCoded: true,
  showClinicalMap: true,
  label: {
    name: 'BETA',
    style: 'ml-2 text-tiny border-[#66B2FF] bg-[#66B2FF]  group-hover:text-[#324FDD] group-hover:bg-[#F3F4F6]'
  },
  sections: [
    {
      id: 'REASON_FOR_EXAM',
      name: 'Reason for Exam',
      requiredInfo: [
        { name: 'Crown material', variable: 'crownMaterial', isStarIcon: true },
        { name: 'Tooth number', variable: 'toothNumber' },
        { name: 'Reason for crown', variable: 'reasonForCrown' },
        { name: 'Referred by (optional)', variable: 'referredBy', isOptional: true }
      ]
    },
    {
      id: 'MEDICAL_HISTORY',
      name: 'Medical History',
      requiredInfo: [
        { name: 'Blood pressure (optional)', variable: 'bloodPressure', isOptional: true },
        { name: 'Heart rate (optional)', variable: 'heartRate', isOptional: true },
        { name: 'Medications ', variable: 'Medications' },
        { name: 'Allergies', variable: 'Allergies' }
      ]
    },
    {
      id: 'PREPARATION',
      name: 'Preparation',
      requiredInfo: [
        { name: 'Pre-op and/or post-op Imaging ', variable: 'imaging' },
        { name: 'Anesthesia (type, method, and amounts)', variable: 'Anesthesia' },
        { name: 'Other medications administered (optional)', variable: 'otherMedications', isOptional: true },
        { name: 'Hemostatic solution (optional)', variable: 'hemostaticSolution', isOptional: true }
      ]
    },
    {
      id: 'CROWN_DELIVERY',
      name: 'Crown delivery',
      requiredInfo: [
        { name: 'Desensitizer (optional)', variable: 'desensitizer', isOptional: true },
        { name: 'Cementation process', variable: 'CementationProcess' }
      ]
    },
    {
      id: 'NEXT_STEPS',
      name: 'Next Steps',
      requiredInfo: [{ name: 'Follow-up (optional)', variable: 'FollowUp', isOptional: true }]
    },
    {
      id: 'ADDITIONAL_NOTES',
      name: 'Additional notes',
      requiredInfo: [{ name: 'Additional notes', variable: 'additionalnotes' }]
    }
  ]
} as const

const soapNote: IPatientIntakeTemplate = {
  id: 'SOAP_NOTE',
  name: 'SOAP Note',
  description: 'Organize your thoughts in the ADA-approved SOAP clinical note format',
  imgName: soapNoteImage,
  showClinicalMap: true,
  isHardCoded: false,
  label: {
    name: 'NEW',
    style: 'border-[#48BDA1] bg-[#48BDA1]'
  },
  sections: [
    {
      id: 'SUBJECTIVE',
      name: 'Subjective (S)',
      requiredInfo: [
        { name: 'Chief Complaint', variable: 'subjective' },
        { name: 'Duration of Symptoms', variable: 'subjective' },
        { name: 'Pain levels and location', variable: 'subjective' },
        { name: 'Medications', variable: 'subjective' },
        { name: 'Allergies', variable: 'subjective' },
        { name: 'Past medical conditions', variable: 'subjective' },
        { name: 'Past surgeries', variable: 'subjective' },
        { name: 'Lifestyle', variable: 'subjective' }
      ]
    },
    {
      id: 'OBJECTIVE',
      name: 'Objective (O)',
      requiredInfo: [
        { name: 'Blood Pressure', variable: 'objective' },
        { name: 'Heart Rate', variable: 'objective' },
        { name: 'Respiration', variable: 'objective' },
        { name: 'Intraoral examinations', variable: 'objective' },
        { name: 'Extraoral examinations', variable: 'objective' }
      ]
    },
    {
      id: 'ASSESSMENT',
      name: 'Assessment (A)',
      requiredInfo: [{ name: 'Diagnosis / Diagnoses', variable: 'assessment' }]
    },
    {
      id: 'PLAN',
      name: 'Plan (P)',
      requiredInfo: [
        { name: 'Treatment Plan', variable: 'plan' },
        { name: 'Referrals', variable: 'plan' }
      ]
    },
    {
      id: 'ADDITIONAL_NOTES',
      name: 'Additional Notes',
      requiredInfo: [{ name: 'Additional notes', variable: 'additionalNotes' }]
    }
  ]
} as const

const procedureTemplates: ITemplate[] = [
  fillingsProcedure,
  rootCanalFillingProcedure,
  implantProcedure,
  crownPermanentProcedure,
  crownTemporaryProcedure,
  prophyProcedure,
  extractionProcedure,
  otherProcedure
]

const notesAllType: ITemplate[] = [examNote, soapNote, procedureNote]

const templateNotes: ITemplate[] = [examNote, soapNote]

export const data = { templateNotes, procedureTemplates, patientSummaryTemplate, notesAllType }

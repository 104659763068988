import { data } from '@/data'
import { IEncounter, ITemplateResponse } from '@/models'

const getProcedureTemplates = async () => {
  await new Promise<void>(resolve => setTimeout(resolve, 0))
  return { Note: data.procedureTemplates, PatientSummary: data.patientSummaryTemplate }
}

const getStatictemplates = async () => {
  await new Promise<void>(resolve => setTimeout(resolve, 0))
  return { Note: data.templateNotes, PatientSummary: data.patientSummaryTemplate }
}

const getNote = async () => {
  await new Promise<void>(resolve => setTimeout(resolve, 0))
  return { Note: data.notesAllType, PatientSummary: data.patientSummaryTemplate }
}

const generateNote = async (encounter: IEncounter): Promise<ITemplateResponse> => {
  await new Promise<void>(resolve => setTimeout(resolve, 0))

  const response = {
    sections: {
      '8377a3fd-2149-4190-8edc-6de5468f6791': `This is a generated note for "Patient Perspective"`,
      'bf706ea2-53bc-430f-a039-3650b94a8876': `This is a generated note for "Medical History"`,
      '7db3f4b4-49dc-43e6-a62c-121b84107caa': `This is a generated note for "Materials and Procedures"`,
      'f1305418-aff6-45b2-ba71-ccb5ebf94946': `This is a generated note for "Diagnosis"`,
      '37bf7ba3-ed47-4472-a123-b546c44be23b': `This is a generated note for "Next Steps"`
    }
  }

  return response
}

const generatePatientSummary = async (encounter: IEncounter): Promise<ITemplateResponse> => {
  await new Promise<void>(resolve => setTimeout(resolve, 0))

  const response = {
    sections: {
      '47b9df4e-e283-4d57-8844-15ed8a6542eb': `This is a generated summary for "Patient Summary"`,
      'af7b3f67-655e-4bd9-87b7-02e213186453': `This is a generated note for "Next Steps"`
    }
  }

  return response
}

const saveToEhr = async (_: IEncounter) => {
  await new Promise<void>(resolve => setTimeout(resolve, 5_000))
  return { success: true }
}

export const api = {
  getStatictemplates,
  getProcedureTemplates,
  generateNote,
  generatePatientSummary,
  saveToEhr,
  getNote
}

import React from 'react'
import { CreateCustomTemplateEdit } from './CreateCustomTemplateEdit.tsx'
import { useStore } from '@/store'
import backIcon from '/assets/images/back-button.svg'

export const CreateCustomTemplate = () => {
  const setView = useStore(state => state.setView)
  const clearCustomTemplate = useStore(state => state.clearCustomTemplate)

  const handleBackButton = () => {
    clearCustomTemplate()
    setView('Setup')
  }

  return (
    <div className="flex h-full flex-col">
      <button onClick={handleBackButton} className="ml-4 mt-4 flex items-center text-lg text-[#010D83] hover:underline">
        <img src={backIcon} alt="Back icon" className="mr-2" />
        Back
      </button>

      <div className="mt-4 flex-1 border-l border-gray-300 p-4">
        <CreateCustomTemplateEdit />
      </div>
    </div>
  )
}

import React from 'react'
import { cn } from '@/util'

export const Variable: React.FC<{
  children: React.ReactNode
  value?: string | string[]
  isTriggerField?: boolean
  optional?: boolean
}> = ({ children, value, isTriggerField = false, optional = false }) => {
  const highlightClass = isTriggerField ? 'bg-[#E0C300] text-black ' : 'bg-blue-500'

  if (value) {
    if (Array.isArray(value)) {
      return (
        <ul className="template-list-variable list-disc pl-6">
          {value.map((v, i) => (
            <li key={i} className="py-1">
              <span className="rounded-md bg-[#1aa383] p-0.5 px-1.5 text-white">{v}</span>
            </li>
          ))}
        </ul>
      )
    }
    return <span className={cn('template-text-variable rounded-md bg-[#1aa383] p-0.5 px-1.5 text-white')}>{value}</span>
  }

  if (optional) {
    return null
  }

  return (
    <span
      className={cn('template-null-variable rounded-md p-0.5 px-1.5 text-white', highlightClass)}
      style={{ marginBottom: '4px', marginTop: '4px', display: 'inline-block' }}
    >
      {children}
    </span>
  )
}

import React, { useEffect, useRef } from 'react'

import { MicrophoneEvent } from '@/services/Microphone'
import { SocketApi } from '@/services/SocketApi'

const socketApi = new SocketApi()

const segmentCount = 30

export const MicLevel = () => {
  const audioLevelRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    const microphone = socketApi.getMicrophone()

    const removeAudioLevelListener = microphone.on(MicrophoneEvent.LEVEL_CHANGE, ({ detail: level }) => {
      const levelIndex = Math.round(segmentCount * (level as number))

      for (let i = 0; i < segmentCount; i++) {
        audioLevelRefs.current[i]?.style.setProperty('opacity', i <= levelIndex ? '1' : '0')
      }
    })

    return removeAudioLevelListener
  }, [])

  return (
    <div className="relative flex h-8 w-[70%] items-center justify-center">
      <div className="absolute inset-0 flex flex-row gap-[3px]">
        {[...Array(segmentCount).keys()].map(index => (
          <React.Fragment key={index}>
            <div className="flex-1 rounded-md border" />
          </React.Fragment>
        ))}
      </div>
      <div className="absolute inset-0 z-10 flex flex-row gap-[3px]">
        {[...Array(segmentCount).keys()].map(index => (
          <React.Fragment key={index}>
            <div
              className="flex-1 rounded-md border border-primary bg-[#acbaff] opacity-0"
              ref={el => (audioLevelRefs.current[index] = el)}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

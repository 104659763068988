import React, { useEffect, useState } from 'react'
import { Button } from '@/components/ui'
import { useStore } from '@/store'
import { copyToClipboard, formatEncounterDate } from '@/util'
import { toast } from 'sonner'
import { useGeneratePdf, useRegenerateNarrative } from '@/hooks/api'
import { CustomTemplateClinicalMap } from './CustomTemplateClinicalMap'
import { ReportIssue } from '../ReportIssue'
import { SocketApi } from '@/services/SocketApi'
import { NARRATIVE_TAB_CONFIGS } from '@/utils/constants'

const socket = new SocketApi()

export const CustomTemplateSidebar: React.FC = () => {
  const { view, customTemplate, editedFinalNote, encounter, setIsPending } = useStore(state => ({
    view: state.view,
    customTemplate: state.customTemplate,
    encounter: state.encounter,
    setIsPending: state.setIsPending,
    editedFinalNote: state.customTemplate?.editedNotes?.templateNote || ''
  }))

  const [content, setContent] = useState<string>('')
  const generatePdf = useGeneratePdf()
  const { mutate: regenerateNarrative } = useRegenerateNarrative()
  const { title, contentKey, fileName, showClinicalMap } = NARRATIVE_TAB_CONFIGS[view] || {}

  useEffect(() => {
    if (view === 'CustomTemplateEdit') {
      setContent(editedFinalNote || customTemplate?.finalNote || '')
    } else {
      const editedContent = customTemplate?.editedNotes[contentKey] || customTemplate[contentKey] || ''
      setContent(editedContent)
    }
  }, [view, customTemplate, contentKey, editedFinalNote])

  const handleCopy = () => {
    if (content) {
      copyToClipboard(`${title}\n\n${content}`)
      toast.success(`${title} copied to clipboard`)
    } else {
      toast.error(`No ${title.toLowerCase()} to copy`)
    }
  }

  const handleDownload = () => {
    if (content) {
      const file = `${formatEncounterDate(encounter?.createdAt ?? new Date())} ${fileName}`

      const promise = generatePdf.mutateAsync({
        fileName: file,
        text: content
      })

      toast.promise(promise, {
        loading: `Generating ${title.toLowerCase()} PDF...`,
        success: `Downloaded ${title.toLowerCase()}`,
        error: `Failed to generate ${title.toLowerCase()}. Please try again.`
      })
    } else {
      toast.error(`No ${title.toLowerCase()} to download`)
    }
  }

  const handleEditClick = () => {
    if (customTemplate?.finalNote) {
      const additionalNotes = customTemplate?.filledValues?.['ADDITIONAL_NOTES'] || ''
      const noteWithAdditionalNotes = additionalNotes
        ? `${customTemplate.finalNote}\n\nAdditional Notes: ${additionalNotes}`
        : customTemplate.finalNote

      setIsPending(true)
      socket.generateCustomTemplateNote(noteWithAdditionalNotes)

      toast.success('Preparing clinical note for editing')
    } else {
      toast.error('No clinical note available to edit')
    }
  }

  const handleRegenerateNarrative = () => {
    regenerateNarrative({
      finalNote: customTemplate?.finalNote || '',
      editedFinalNote: customTemplate?.editedNotes?.templateNote
    })
  }

  return (
    <div className="flex h-full max-w-full flex-col overflow-hidden p-4">
      {showClinicalMap ? (
        <>
          <div className="max-w-full flex-1 overflow-y-auto overflow-x-hidden">
            <CustomTemplateClinicalMap />
          </div>
          <div className="mt-4 flex flex-col gap-4">
            <Button size="lg" onClick={handleEditClick}>
              Edit and Finalize Clinical Note
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4 flex flex-col gap-4">
            <Button size="lg" onClick={handleCopy} disabled={!content}>
              Copy {title}
            </Button>
            <Button size="lg" variant="outline" onClick={handleDownload} disabled={!content}>
              Download {title} as PDF
            </Button>
            <Button size="lg" variant="outline" onClick={handleRegenerateNarrative} disabled={!content}>
              Regenerate {title}
            </Button>
          </div>
        </>
      )}
      <div className="mt-4">
        <ReportIssue />
      </div>
    </div>
  )
}

export default CustomTemplateSidebar

import { CustomLinks } from '@/Links'
import { Button, Checkbox, Label } from '@/components/ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { cn } from '@/util'
import bolaLogo from '/assets/bola-logo.svg'

export const TermsAndConditions = () => {
  const navigate = useNavigate()

  const [termsOfUse, setTermsOfUse] = useState(false)

  const handleTermsOfUseChange = () => {
    setTermsOfUse(!termsOfUse)
  }

  const handleClickonLink = () => {
    window.open(CustomLinks.termsAndConditions)
  }

  return (
    <section className="m-44 min-h-screen">
      <header className="h-50 flex">
        <img src={bolaLogo} className="h-20" alt="Bola Logo" />
      </header>
      <main className="mt-28">
        <article>
          <h2 className="text-4xl font-bold">Please review our terms of use</h2>
        </article>
        <article>
          <p className="mt-5 text-2xl">
            Before you get started, you need to review and agree to our
            <Button className="pl-1.5 text-2xl" variant="link" onClick={handleClickonLink}>
              terms of use.
            </Button>
          </p>
        </article>

        <hr className="mx-auto mt-20 bg-black" />

        <form className="mt-16">
          <Label className="cursor-pointer text-lg">
            <Checkbox value={termsOfUse.toString()} onClick={handleTermsOfUseChange} className="mr-1" />I have read and
            agree to the terms of use
          </Label>
          <div className="flex max-w-lg items-center space-x-2">
            <Button
              className={`grow-1 mt-11 h-12 w-32 rounded-md text-white ${!termsOfUse ? 'bg-gray-500' : 'bg-primary'}`}
              disabled={!termsOfUse}
              onClick={() => {
                navigate('/welcome')
              }}
            >
              Continue
            </Button>
          </div>
        </form>
      </main>
    </section>
  )
}

import { Check } from 'lucide-react'
import posthog from 'posthog-js'
import { useState } from 'react'

import { Button } from '@/components/ui'
import { ClinicalMap } from './ClinicalMap'
import { useGenerateNote } from '@/hooks/api'
import { useStore } from '@/store'
import { copyToClipboard, formatTemplateResponse, getSectionContent } from '@/util'
import { ReportIssue } from './ReportIssue'
import { toast } from 'sonner'

export const TranscriptSidebar = () => {
  const encounter = useStore(state => state.encounter)
  const setView = useStore(state => state.setView)
  const [isCopyingTranscript, setIsCopyingTranscript] = useState(false)
  const updateEncounter = useStore(state => state.updateEncounter)
  const licenseKey = useStore(state => state.licenseKey)
  const activeTemplate = useStore(state => state.activeTemplate)
  const generateNote = useGenerateNote()

  posthog.identify(licenseKey, {
    licenseKey,
    //TODO: REMOVE THIS ONCE POSTHOG DASHBOARDS ALL USE "licenseKey" INSTEAD OF "License Key"
    'License Key': licenseKey
  })

  const onCopyTranscriptClick = () => {
    posthog.capture('user_copied_transcript')
    void copyToClipboard(
      encounter?.transcript
        ? formatTemplateResponse(activeTemplate, encounter.transcript, encounter.deletedSections, true)
        : ''
    )
    setIsCopyingTranscript(true)
    setTimeout(() => setIsCopyingTranscript(false), 4000)
  }

  const extractTextFormatted = element => {
    if (element === null) return ''
    let text = ''
    element.childNodes.forEach(child => {
      if (child.classList?.contains('template-section-title')) {
        text += `${child.textContent.toUpperCase()}\n`
      } else if (child.classList?.contains('template-section')) {
        text += `${extractTextFormatted(child)}\n\n\n`
      } else if (child.classList?.contains('template-section-subtitle')) {
        text += `\n\n${child.textContent}\n`
      } else if (child.classList?.contains('template-section-content')) {
        child.childNodes.forEach(child => {
          if (child.nodeType === Node.TEXT_NODE) {
            text += `${child.textContent}`
          } else if (child.tagName === 'UL') {
            if (!text.endsWith('\n')) {
              text += '\n'
            }
            child.childNodes.forEach(listItem => {
              text += `- ${listItem.textContent}\n`
            })
          } else if (child.classList?.contains('template-text-variable')) {
            text += `${child.textContent}`
          } else if (child.classList?.contains('template-null-variable')) {
            text += `${child.textContent}`
          } else {
            text += `${child.textContent}`
          }
        })
        text += `\n`
      } else {
        console.log(child)
      }
    })
    return text.replace(/\n{3,}/g, '\n\n').replace(/\. (?!\n)/g, '.\n')
  }

  const editNote = () => {
    posthog.capture('user_clicked_edit_note')
    try {
      const templateRaw = extractTextFormatted(document.querySelector<HTMLElement>('#template'))
      updateEncounter({ templateRaw })
      setView('Edit')
    } catch (e) {
      toast.error('There was an error while finalizing the note')
    }
  }

  const areThereMoreThan20Words = (): boolean => {
    if (!encounter?.transcript?.sections) return false

    const totalWordCount = Object.keys(encounter.transcript.sections).reduce((acc, section) => {
      const sectionContent = getSectionContent(encounter.transcript?.sections[section])
      if (sectionContent == null) return acc
      return acc + sectionContent.split(/\s+/).length
    }, 0)

    return totalWordCount >= 20
  }

  return (
    <>
      <div className="p-8">
        <ClinicalMap />
        {!areThereMoreThan20Words() && !encounter?.isTranscribing && !encounter?.transcriptTemplate?.isHardCoded && (
          <span className="mt-4 block text-sm text-gray-500">
            There is not enough content to generate a note...Please dictate more content to continue (20 words minimum).
          </span>
        )}
        {areThereMoreThan20Words() && (
          <>
            <Button
              size="lg"
              className="mt-8 w-full"
              onClick={() => {
                posthog.capture('user_reviewed_note')
                if (!generateNote.isPending && !encounter?.isGeneratingNote) {
                  updateEncounter({ isTranscribing: false })
                  generateNote.mutate(encounter!)
                }

                setView('Note')
              }}
            >
              {encounter?.wasNoteUpdatedAfterGeneration ? 'Update note' : 'Generate note'}
            </Button>
            <Button size="lg" variant="outline" className="mt-2 w-full" onClick={onCopyTranscriptClick}>
              {isCopyingTranscript ? (
                <>
                  <Check className="mr-1.5 size-4 text-green group-hover/button:text-green" />
                  Copied
                </>
              ) : (
                'Copy transcript'
              )}
            </Button>
          </>
        )}
        {encounter?.transcriptTemplate.isHardCoded && (
          <>
            <Button size="lg" className="mt-2 w-full" onClick={editNote}>
              Finalize Note
            </Button>
          </>
        )}
        <div className="mt-2">
          <ReportIssue />
        </div>
      </div>
    </>
  )
}

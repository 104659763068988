import React, { useState, useEffect } from 'react';

interface NarrativeEditorProps {
  narrative: string | null;
  editedNarrative: string | null;
  setEditedNarrative: (value: string) => void;
  placeholder: string;
}

export const NarrativeEditor: React.FC<NarrativeEditorProps> = ({
  narrative,
  editedNarrative,
  setEditedNarrative,
  placeholder,
}) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editedNarrative) {
      setContent(editedNarrative);
    } else if (narrative) {
      setContent(narrative);
    }
  }, [narrative, editedNarrative]);

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
    setEditedNarrative(e.target.value);
  };

  return (
    <div className="create-custom-template-container flex h-full flex-col">
      <textarea
        value={content}
        onChange={handleContentChange}
        className="custom-template-textarea w-full flex-grow resize-none rounded border border-gray-300 px-8 pb-4 pt-12 text-lg outline-none"
        placeholder={placeholder}
      />
    </div>
  );
};

export default NarrativeEditor;

import { ChevronDown } from 'lucide-react'
import { Button, Drawer, DrawerContent, DrawerTrigger, Label, ScrollArea } from './ui'
import { cn } from '../util'
import { useState } from 'react'
import { useStore } from '@/store'

import { EncounterProgress } from './EncounterProgress'

export const ClinicalMap = () => {
  const [isShowingDetails, setIsShowingDetails] = useState(true)
  const activeTemplate = useStore(state => state.activeTemplate)
  return (
    <div id="clinicalMap">
      {activeTemplate?.showClinicalMap ? (
        <div className="flex flex-row items-center justify-between">
          <Label className="-mb-1 text-lg text-black">Clinical map</Label>
          <Button
            variant="ghost"
            className="-mr-4 hidden gap-1 text-[#0f172a] md:flex"
            onClick={() => setIsShowingDetails(isShowing => !isShowing)}
          >
            {isShowingDetails ? 'Hide details' : 'Show details'}
            <ChevronDown
              className={cn('size-4 transition-all duration-150 ease-out', isShowingDetails && 'rotate-180')}
            />
          </Button>
          <Drawer>
            <DrawerTrigger asChild>
              <Button variant="ghost" className="-mr-4 gap-1 text-[#0f172a] md:hidden">
                {isShowingDetails ? 'Hide details' : 'Show details'}
                <ChevronDown
                  className={cn('size-4 transition-all duration-150 ease-out', isShowingDetails && 'rotate-180')}
                />
              </Button>
            </DrawerTrigger>
            <DrawerContent>
              <ScrollArea className="h-[calc(100lvh-14rem)]">
                <div className="px-8 pb-8">
                  <EncounterProgress showDetails />
                </div>
              </ScrollArea>
            </DrawerContent>
          </Drawer>
        </div>
      ) : (
        <div className="flex flex-col justify-center">
          <Label className="text-header-black text-lg">Instructions:</Label>

          <p className="mt-4 ">
            Continue speaking to generate a transcript. Once completed, click 'Generate Note' to view a summarized,
            bullet-pointed version of your transcript.
          </p>
        </div>
      )}
      {activeTemplate?.showClinicalMap ? <EncounterProgress showDetails={isShowingDetails} /> : ''}
    </div>
  )
}

import React from 'react'
import { TabsList, TabsTrigger, Label } from '@/components/ui'
import { cn } from '@/util'
import { CUSTOM_TEMPLATE_VIEWS } from '@/utils/constants'

const HeaderTabs = ({ view, customTemplate, encounter, hasTabs }: any) => {
  return (
    <header className={cn('flex justify-center p-8 pb-4 md:justify-normal', !hasTabs && 'p-0 pb-2')}>
      {CUSTOM_TEMPLATE_VIEWS.includes(view) ? (
        <TabsList>
          <TabsTrigger value="CustomTemplateEdit">Final Note</TabsTrigger>
          <TabsTrigger value="CustomTemplatePatientNarrative" disabled={!customTemplate?.patientNarrative}>
            Patient Summary
          </TabsTrigger>
          <TabsTrigger value="InsuranceNarrative" disabled={!customTemplate?.insuranceNarrative}>
            Insurance Narrative
          </TabsTrigger>
        </TabsList>
      ) : (
        <TabsList className={cn(!hasTabs && 'hidden')}>
          <TabsTrigger value="Transcript">Transcript</TabsTrigger>
          <TabsTrigger value="Note" disabled={!encounter?.note}>
            Note
          </TabsTrigger>
          <TabsTrigger value="Summary" disabled={!encounter?.note}>
            Patient Summary
          </TabsTrigger>
        </TabsList>
      )}
    </header>
  )
}

export default HeaderTabs

import posthog from 'posthog-js'
import { toast } from 'sonner'
import { Button } from '@/components/ui'
import { useGeneratePatientSummary, useGeneratePdfFromSections } from '@/hooks/api'
import { useStore } from '@/store'
import { copyToClipboard, formatEncounterDate, formatTemplateResponse } from '@/util'
import { ReportIssue } from './ReportIssue'
import { data } from '@/data'

export const SummarySidebar = () => {
  const encounter = useStore(state => state.encounter)
  const activeTemplate = useStore(state => state.activeTemplate)
  const updateEncounter = useStore(state => state.updateEncounter)
  const generatePdf = useGeneratePdfFromSections()
  const generateSummary = useGeneratePatientSummary()

  if (!encounter) {
    return null
  }

  const onRegenerateSummaryClick = async () => {
    posthog.capture('user_regenerated_summary')
    updateEncounter({ isTranscribing: false })
    generateSummary.mutate(encounter)
  }

  const onCopySummaryClick = () => {
    posthog.capture('user_copied_summary')
    void copyToClipboard(
      encounter.patientSummary
        ? formatTemplateResponse(activeTemplate, encounter.patientSummary, encounter.deletedSections)
        : ''
    )
  }

  const onDownloadSummaryClick = () => {
    posthog.capture('user_downloaded_summary')
    if (generatePdf.isPending) {
      return
    }

    const promise = generatePdf.mutateAsync({
      activeTemplate: data.patientSummaryTemplate,
      fileName: `${formatEncounterDate(encounter.createdAt)} Summary`,
      response: encounter.patientSummary,
      deletedSections: encounter.deletedSections
    })

    toast.promise(promise, {
      loading: 'Generating PDF...',
      success: 'Downloaded summary',
      error: 'Failed to generate PDF. Please try again.'
    })
  }

  return (
    <div className="p-8">
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col items-stretch gap-4">
          <Button
            size="lg"
            onClick={onDownloadSummaryClick}
            disabled={generateSummary.isPending || !encounter.patientSummary}
          >
            Download summary
          </Button>
          <Button size="lg" variant="outline" onClick={onCopySummaryClick}>
            Copy summary
          </Button>
          <Button
            size="lg"
            variant="outline"
            onClick={() => void onRegenerateSummaryClick()}
            disabled={generateSummary.isPending}
          >
            Regenerate summary
          </Button>
        </div>
        <div className="mt-4">
          <ReportIssue />
        </div>
      </div>
    </div>
  )
}

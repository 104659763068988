import React, { useState, useEffect } from 'react'
import { SocketApi, SocketApiListenEvents } from '@/services/SocketApi'
import { useStore } from '@/store'
import { toast } from 'sonner'
import { Variable } from '../templates/ui/Variable'
import './CustomTemplate.css'

const socketApi = new SocketApi()

export const CreateCustomTemplateEdit: React.FC = () => {
  const {
    userTemplate,
    generatedNote,
    isTemplateGenerated,
    setUserTemplate,
    setGeneratedTemplate,
    setIsTemplateGenerated,
    setIsPending
  } = useStore(state => ({
    userTemplate: state.customTemplate.userTemplate,
    generatedNote: state.customTemplate.generatedTemplate,
    isTemplateGenerated: state.customTemplate.isTemplateGenerated,
    setUserTemplate: state.setUserTemplate,
    setGeneratedTemplate: state.setGeneratedTemplate,
    setIsTemplateGenerated: state.setIsTemplateGenerated,
    setIsPending: state.setIsPending
  }))

  const [content, setContent] = useState<string>(userTemplate || '')

  const applyInitialPlaceholderHighlight = (template: string) => {
    const parts: (string | JSX.Element)[] = []
    template.split(/(\[\[.*?\]\])/).forEach(part => {
      const match = part.match(/\[\[(.*?)\]\]/)
      if (match) {
        parts.push(<Variable key={match[1]}>{match[1]}</Variable>)
      } else {
        parts.push(part)
      }
    })
    return parts
  }

  useEffect(() => {
    socketApi.onUniqueListener(SocketApiListenEvents.CUSTOM_TEMPLATE_CREATED, data => {
      if (data?.customTemplate?.note) {
        setGeneratedTemplate(data.customTemplate)
        setIsTemplateGenerated(true)
        setIsPending(false)
        toast.success('Template created successfully')
      } else {
        setIsTemplateGenerated(false)
      }
    })
  }, [setGeneratedTemplate, setIsTemplateGenerated, socketApi.getSocket()])

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = event.target.value
    setContent(newContent)
    setUserTemplate(newContent)
  }

  return (
    <div className="create-custom-template-container flex h-full flex-col">
      {!isTemplateGenerated ? (
        <textarea
          dir="ltr"
          value={content}
          onChange={handleInputChange}
          className="custom-template-textarea w-full flex-grow resize-none rounded border border-gray-300 px-8 pb-4 pt-12 text-lg outline-none"
          placeholder="Paste your custom template here..."
        />
      ) : (
        <div className="sleek-scrollbar generated-note-container mt-4 p-2 text-sm leading-relaxed text-gray-800">
          {applyInitialPlaceholderHighlight(generatedNote?.note || '')}
        </div>
      )}
    </div>
  )
}

import io from 'socket.io-client'
import { Socket } from 'socket.io-client'
import { toast } from 'sonner'

import {
  IPatientIntakeProgress,
  IPatientIntakeResponse,
  IPatientSummaryResponse,
  PatientIntakeProgressSchema,
  PatientIntakeResponseSchema,
  PatientSummaryResponseSchema
} from '@/data'
import * as Sentry from '@sentry/react'
import { useStore } from '@/store'

import { Microphone, MicrophoneEvent } from './Microphone'
import { z } from 'zod'

export enum SocketApiListenEvents {
  TRANSCRIPT = 'transcript',
  NOTE_GENERATED = 'note-generated',
  CLINICAL_MAP_GENERATED = 'clinical-map-generated',
  ERROR = 'error',
  CONNECTION_ERROR = 'connect_error',
  CONNECTION_TIMEOUT = 'connect_timeout',
  RECONNECTING = 'reconnecting',
  RECONNECT_ERROR = 'reconnect_failed',
  RECONNECT_ATTEMPT = 'reconnect_attempt',
  DISCONNECTED = 'disconnect',
  CONNECTED = 'connect',
  REALTIME_TRANSCRIPT = 'realtime-transcription',
  TEMPLATE_GENERATED = 'template-generated',
  PATIENT_SUMMARY_GENERATED = 'patient-summary-generated',
  ERROR_NOTE_GENERATION = 'error-note-generation',
  ERROR_PATIENT_SUMMARY_GENERATION = 'error-patient-summary-generation',
  ERROR_REALTIME_TRANSCRIPTION = 'error-realtime-transcription',
  ERROR_CLINICAL_MAP_GENERATION = 'error-clinical-map-generation',
  ERROR_TEMPLATE_GENERATION = 'error-template-generation',
  ERROR_CUSTMO_TEMPLATE__NOTE_GENERATION = 'error-custom-template-note-generation',
  ERROR_CUSTOM_TEMPLATE_NOTE_GENERATION = 'error-custom-template-note-generation',
  ERROR_CUSTOM_TEMPLATE_CREATION = 'error-custom-template-creation',
  ERROR_CUSTOM_TEMPLATE_GENERATION = 'error-custom-template-generation',
  CLOSING_CONNECTION_MAX_DURATION = 'closing-connection-max-duration',
  CUSTOM_TEMPLATE_GENERATED = 'custom-template-generated',
  CUSTOM_TEMPLATE_CREATED = 'custom-template-created',
  CUSTOM_TEMPLATE_NOTE_GENERATED = 'custom-template-note-generated',
  PATIENT_NARRATIVE_GENERATED = 'patient-narrative-generated',
  INSURANCE_NARRATIVE_GENERATED = 'insurance-narrative-generated'
}

export enum SocketApiEmitEvents {
  TRANSCRIBE = 'transcribe',
  GENERATE_NOTE = 'generate-note',
  GENERATE_PATIENT_SUMMARY = 'generate-patient-summary',
  GENERATE_CLINICAL_MAP = 'generate-clinical-map',
  GENERATE_TEMPLATE = 'generate-template',
  CREATE_CUSTOM_TEMPLATE = 'create-custom-template',
  GENERATE_CUSTOM_TEMPLATE = 'generate-custom-template',
  FINISH_ENCOUNTER = 'finish-encounter',
  GENERATE_CUSTOM_TEMPLATE_NOTE = 'generate-custom-template-note',
  GENERATE_CUSTOM_TEMPLATE_NARRATIVES = 'generate-custom-template-narrative'
}

export interface IGenerateTemplate {
  type: string
  transcript: string
}

export interface IGeneratedTemplate {
  type: string
  template: string
}

export interface INote {
  sections: [
    {
      name: string
      content: string
    }
  ]
}

export class SocketApi {
  private static instance: SocketApi | null = null
  private socket: Socket | null = null
  private microphone = new Microphone()
  private isReconnecting = false
  private lastErrorToastAt = 0
  private maxTimeout = false
  private isConnecting = false
  private microphoneListenerUnsubscribe: (() => void) | null = null

  constructor() {
    if (SocketApi.instance) {
      return SocketApi.instance
    } else {
      SocketApi.instance = this
    }
    this.connect()
    useStore.subscribe((state, prevState) => {
      if (state.encounter?.isTranscribing !== prevState.encounter?.isTranscribing && state.encounter?.isTranscribing) {
        this.connect()
      }
    })
  }

  connect(): void {
    if (this.socket?.connected || this.isConnecting) {
      return
    }
    this.isConnecting = true
    this.socket = io(import.meta.env.VITE_API, {
      timeout: 60000,
      transports: ['websocket'],
      transportOptions: {
        websocket: {
          withCredentials: false
        },
        polling: {
          withCredentials: false
        }
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1500,
      reconnectionDelayMax: 2000
    }).on('connect', () => {
      this.maxTimeout = false
      this.lastErrorToastAt = 0
      this.isConnecting = false
      console.log('Connected to socket')
      if (this.isReconnecting) {
        this.isReconnecting = false
        toast.success('We have successfully reconnected to our server.')
      }
      setInterval(() => {
        const state = useStore.getState()
        if (
          !state.encounter?.isTranscribing ||
          !this.isConnected() ||
          state.activeTemplate?.isHardCoded ||
          state.activeCustomTemplate
        )
          return
        const sections = useStore.getState().encounter?.transcript?.sections
        if (!sections) return
        const plainText = Object.keys(sections)
          .reduce((acc: string[], key) => {
            acc.push(sections[key] ?? '')
            return acc
          }, [])
          .join('\n')
        this.generateClinicalMap(plainText)
      }, 10000)
    })
    this.microphoneListener()
    this.generatedNoteListener()
    this.generatedCustomTemplateNoteListener()
    this.generatedPatientNarrativeListener()
    this.generatedPatientSummaryListener()
    this.generatedInsuranceNarrativeListener()
    this.generatedClinicalMapListener()
    this.socketListeners()
  }

  isConnected(): boolean {
    return this.socket?.connected ?? false
  }

  on(event: SocketApiListenEvents, callback: (data: any) => void): void {
    this.socket?.on(event, callback)
  }

  onUniqueListener(event: SocketApiListenEvents, callback: (data: any) => void): void {
    this.socket?.removeAllListeners(event)
    this.socket?.on(event, callback)
  }

  emit(event: SocketApiEmitEvents, data: any): void {
    this.ensureConnected(() => {
      this.socket?.emit(event, data)
    })
  }

  microphoneListener(): void {
    if (this.microphoneListenerUnsubscribe) {
      this.microphoneListenerUnsubscribe()
    }

    this.microphoneListenerUnsubscribe = this.microphone.on(MicrophoneEvent.DATA, event => {
      if (this.isConnected() && useStore.getState().encounter?.isTranscribing) {
        this.emit(SocketApiEmitEvents.TRANSCRIBE, event.detail)
      } else if (useStore.getState().encounter?.isTranscribing) {
        this.setTranscribing(false)
        if (!this.maxTimeout) {
          this.errorToast()
        }
      }
    })
  }

  getMicrophone(): Microphone {
    return this.microphone
  }

  getSocket() {
    return this.socket
  }

  generateCustomTemplate(userTemplate: string, rawTranscript: string): void {
    if (!userTemplate) {
      Sentry.addBreadcrumb({
        message: 'User template not available',
        level: 'error',
        category: 'Generate Custom Template'
      })
      return
    }
    this.emit(SocketApiEmitEvents.GENERATE_CUSTOM_TEMPLATE, { userTemplate, rawTranscript })
  }

  ensureConnected(callback: () => void): void {
    if (this.isConnected()) {
      callback()
    } else {
      this.connect()
      this.on(SocketApiListenEvents.CONNECTED, () => {
        callback()
      })
    }
  }

  generateNote(type: string, note: string | null): void {
    this.emit(SocketApiEmitEvents.GENERATE_NOTE, { type, note })
    useStore.getState().updateEncounter({ isGeneratingNote: true })
  }

  generateCustomTemplateNote(note: string | null): void {
    this.emit(SocketApiEmitEvents.GENERATE_CUSTOM_TEMPLATE_NOTE, note)
    useStore.getState().updateEncounter({ isGeneratingNote: true })
  }

  generateNarrative(note: string | null): void {
    this.emit(SocketApiEmitEvents.GENERATE_CUSTOM_TEMPLATE_NARRATIVES, note)
  }

  generatePatientSummary(note: string | null): void {
    this.emit(SocketApiEmitEvents.GENERATE_PATIENT_SUMMARY, note)
    useStore.getState().updateEncounter({ isGeneratingPatientSummary: true })
  }

  generateClinicalMap(note: string): void {
    if (this.isConnected()) {
      this.emit(SocketApiEmitEvents.GENERATE_CLINICAL_MAP, note)
    } else {
      this.setTranscribing(false)
    }
  }

  generateTemplate(type: string, transcript: string): void {
    this.emit(SocketApiEmitEvents.GENERATE_TEMPLATE, { type, transcript })
  }

  createCustomTemplate(template: string): void {
    this.emit(SocketApiEmitEvents.CREATE_CUSTOM_TEMPLATE, template)
  }

  finishEncounter(): void {
    this.emit(SocketApiEmitEvents.FINISH_ENCOUNTER, {})
  }

  disconnectAndCleanup(): void {
    if (this.socket) {
      this.socket.removeAllListeners()
    }
    if (this.microphoneListenerUnsubscribe) {
      this.microphoneListenerUnsubscribe()
      this.microphoneListenerUnsubscribe = null
    }
  }

  private generatedNoteListener(): void {
    this.on(SocketApiListenEvents.NOTE_GENERATED, (data: object) => {
      let note: IPatientIntakeResponse = { sections: {} }
      try {
        console.log(data)
        note = PatientIntakeResponseSchema.parse(data)
      } catch (e) {
        if (e instanceof z.ZodError) {
          console.log(e.issues)
        }
        console.error('Error parsing generated note', e)
        Sentry.addBreadcrumb({ message: `Error Parsing Note ${e}`, level: 'error', category: 'Socket Connection' })
      }

      if (note) {
        useStore.getState().updateEncounter({ note, isGeneratingNote: false, wasNoteUpdatedAfterGeneration: false })
      }
    })
  }

  private generatedCustomTemplateNoteListener(): void {
    this.on(SocketApiListenEvents.CUSTOM_TEMPLATE_NOTE_GENERATED, (data: any) => {
      try {
        if (data.template) {
          useStore.getState().updateEncounter({ isGeneratingNote: false })

          useStore.getState().setFinalNote(data.template)
          useStore.getState().setView('CustomTemplateEdit')
        }
      } catch (e) {
        console.error('Error parsing generated note', e)
        Sentry.addBreadcrumb({ message: `Error Parsing Note ${e}`, level: 'error', category: 'Socket Connection' })
      }
    })
  }

  private generatedPatientNarrativeListener(): void {
    this.on(SocketApiListenEvents.PATIENT_NARRATIVE_GENERATED, (data: any) => {
      if (data) {
        try {
          console.log('insurance narrative generated: ', data)
          useStore.getState().setEditedPatientNarrative('')
          useStore.getState().setPatientNarrative(data.narrative)
          console.log('data received for patient: ', data)
        } catch (e) {
          console.error('Error parsing generated patient summary', e)
          Sentry.addBreadcrumb({
            message: `Error parsing generated patient summary ${e}`,
            level: 'error',
            category: 'Socket Connection'
          })
        }
      }
    })
  }

  private generatedInsuranceNarrativeListener(): void {
    this.on(SocketApiListenEvents.INSURANCE_NARRATIVE_GENERATED, (data: any) => {
      if (data) {
        console.log('insurance narrative generated: ', data)
        try {
          useStore.getState().setEditedInsuranceNarrative('')
          useStore.getState().setInsuranceNarrative(data.narrative)
        } catch (e) {
          console.error('Error parsing generated insurance narrative', e)
          Sentry.addBreadcrumb({
            message: `Error parsing generated insurance narrative ${e}`,
            level: 'error',
            category: 'Socket Connection'
          })
        }
      }
    })
  }

  private generatedPatientSummaryListener(): void {
    this.on(SocketApiListenEvents.PATIENT_SUMMARY_GENERATED, (data: object) => {
      let patientSummary: IPatientSummaryResponse | null = null
      if (data) {
        try {
          patientSummary = PatientSummaryResponseSchema.parse(data)
        } catch (e) {
          console.error('Error parsing generated patient summary', e)
          Sentry.addBreadcrumb({
            message: `Error parsing generated patient summary ${e}`,
            level: 'error',
            category: 'Socket Connection'
          })
        }
      }

      if (patientSummary) {
        useStore.getState().updateEncounter({ patientSummary, isGeneratingPatientSummary: false })
      }
    })
  }

  private generatedClinicalMapListener(): void {
    this.on(SocketApiListenEvents.CLINICAL_MAP_GENERATED, (data: object) => {
      let clinicalMap: IPatientIntakeProgress | null = null

      try {
        console.log('Clinical Map: ', data)
        clinicalMap = PatientIntakeProgressSchema.parse({
          sections: Object.entries(data).reduce(
            (mapping, [sectionId, complete]) => ({
              ...mapping,
              [sectionId]: { progress: complete ? 100 : 0 }
            }),
            []
          )
        })
      } catch (e) {
        console.error('Error parsing generated clinical map', e)
      }

      if (clinicalMap) {
        useStore.getState().updateEncounter({ progress: clinicalMap })
      }
    })
  }

  private socketListeners(): void {
    this.on(SocketApiListenEvents.ERROR, data => {
      this.setTranscribing(false)
      useStore.getState().setIsPending(false)
      this.errorToast()
      console.error('Error ', data)
      Sentry.addBreadcrumb({ message: `Socket Error ${data}`, level: 'error', category: 'Socket Connection' })
    })
    this.on(SocketApiListenEvents.CONNECTION_ERROR, data => {
      this.setTranscribing(false)
      useStore.getState().setIsPending(false)
      this.errorToast()
      console.error('Connection Error ', data)
      Sentry.addBreadcrumb({
        message: `Socket Connection Error ${data}`,
        level: 'error',
        category: 'Socket Connection'
      })
    })
    this.on(SocketApiListenEvents.CONNECTION_TIMEOUT, data => {
      this.setTranscribing(false)
      useStore.getState().setIsPending(false)
      Sentry.addBreadcrumb({
        message: `Socket Connection Timeout Error ${data}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Connection Timeout ', data)
    })
    this.on(SocketApiListenEvents.RECONNECT_ERROR, data => {
      this.isReconnecting = false
      this.setTranscribing(false)
      useStore.getState().setIsPending(false)
      this.errorToast()
      Sentry.addBreadcrumb({
        message: `Socket Connection Reconnect Error ${data}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Reconnecting Error ', data)
    })
    this.on(SocketApiListenEvents.RECONNECTING, data => {
      this.isReconnecting = true
      toast.warning(`We are currently reconnecting to our server. Thank you for your patience.`)
      Sentry.addBreadcrumb({
        message: `Socket Connection Reconnect Error ${data}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.log('Reconnecting ', data)
    })
    this.on(SocketApiListenEvents.ERROR_NOTE_GENERATION, e => {
      useStore.getState().updateEncounter({ isGeneratingNote: false })
      toast.error(`There was an error generating your note.`)
      Sentry.addBreadcrumb({
        message: `Socket Connection Note Generation Error ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating note', e)
    })
    this.on(SocketApiListenEvents.ERROR_PATIENT_SUMMARY_GENERATION, e => {
      useStore.getState().updateEncounter({ isGeneratingPatientSummary: false })
      toast.error(`There was an error generating your patient summary.`)
      Sentry.addBreadcrumb({
        message: `Socket Connection Patient Summary Generation Error ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating patient summary', e)
    })
    this.on(SocketApiListenEvents.ERROR_CLINICAL_MAP_GENERATION, e => {
      toast.error(`There was an error generating your clinical map.`)
      Sentry.addBreadcrumb({
        message: `Socket Connection Clinial Map Generation Error ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating clinical map', e)
    })
    this.on(SocketApiListenEvents.ERROR_TEMPLATE_GENERATION, e => {
      toast.error(`There was an error generating your template.`)
      Sentry.addBreadcrumb({ message: `Error generating template ${e}`, level: 'error', category: 'Socket Connection' })
      console.error('Error generating template', e)
    })
    this.on(SocketApiListenEvents.ERROR_CUSTOM_TEMPLATE_NOTE_GENERATION, e => {
      toast.error(`There was an error generating your custom template note.`)
      useStore.getState().setIsPending(false)
      Sentry.addBreadcrumb({
        message: `Error generating custom template note ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating custom template note', e)
    })
    this.on(SocketApiListenEvents.ERROR_CUSTOM_TEMPLATE_CREATION, e => {
      toast.error(`There was an error creating your custom template.`)
      useStore.getState().setIsPending(false)
      Sentry.addBreadcrumb({
        message: `Error creating custom template ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error creating custom template', e)
    })
    this.on(SocketApiListenEvents.ERROR_CUSTOM_TEMPLATE_GENERATION, e => {
      toast.error(`There was an error generating your custom template.`)
      useStore.getState().setIsPending(false)
      Sentry.addBreadcrumb({
        message: `Error generating custom template ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating custom template', e)
    })
    this.on(SocketApiListenEvents.RECONNECT_ATTEMPT, attempt => {
      Sentry.addBreadcrumb({
        message: `Socket reconnect attempt: ${attempt}`,
        level: 'info',
        category: 'Socket Connection'
      })
      console.error('Socket reconnect attempt:', attempt)
    })
    this.on(SocketApiListenEvents.DISCONNECTED, e => {
      this.setTranscribing(false)
      useStore.getState().setIsPending(false)
      this.isConnecting = false
      this.disconnectAndCleanup()
      Sentry.addBreadcrumb({ message: `Socket Disconnected ${e}`, level: 'info', category: 'Socket Connection' })
      console.error('Socket disconnected', e)
    })
    this.on(SocketApiListenEvents.CLOSING_CONNECTION_MAX_DURATION, e => {
      if (useStore.getState().encounter?.isTranscribing) {
        toast.error('Connection has timed out! Please click on the mic to continue')
        this.setTranscribing(false)
        useStore.getState().setIsPending(false)
      }
      this.maxTimeout = true
    })
  }

  private setTranscribing(isTranscribing: boolean): void {
    useStore.getState().updateEncounter({ isTranscribing })
  }

  private errorToast(): void {
    if (Date.now() - this.lastErrorToastAt < 30000) return
    this.lastErrorToastAt = Date.now()
    toast.error(
      `We're having a hard time connecting to our server. Please wait while we attempt to reconnect. Thank you for your patience.`
    )
  }
}
